<template>
  <v-app @click.self="closeIfMobile">
    <!-- Top app bar -->
    <v-app-bar app color="primary" dark>
      <v-btn icon @click.stop="toggleMenu">
        <v-icon>{{ expanded ? 'mdi-chevron-left' : 'mdi-menu' }}</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-4">CareerFit MVP</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu transition="slide-y-transition">
        <template v-slot:activator="{ props }">
          <div class="d-flex align-center">
            <span class="mr-2">{{ firstName }} {{ lastInitial }}</span>
            <v-btn icon v-bind="props">
              <v-icon>mdi-account-circle</v-icon>
            </v-btn>
          </div>
        </template>
        <v-list>
          <v-list-item
            v-for="(item) in profileItems"
            :key="item.title"
            :to="item.to"
            link
          >
            <v-list-item-title class="d-flex align-center">
              <v-icon color="primary" class="mr-3">{{ item.icon }}</v-icon>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <!-- Overlay when menu is expanded on mobile -->
    <div 
      v-if="expanded && isMobile" 
      class="menu-overlay"
      @click="expanded = false"
    ></div>

    <!-- Single expandable sidebar -->
    <div class="expandable-sidebar" :class="{ 'expanded': expanded }">
      <!-- Navigation items -->
      <div class="sidebar-content">
        <!-- Regular navigation items -->
        <div class="nav-items">
          <div 
            v-for="(item, index) in navbarItems.slice(0, navbarItems.length - 1)" 
            :key="index"
            class="sidebar-item"
            :class="{ 'active': $route.path === item.to }"
            @click="navigateTo(item.to)"
          >
            <v-icon :color="$route.path === item.to ? 'primary' : 'grey-darken-1'" class="item-icon">
              {{ item.icon }}
            </v-icon>
            <span class="item-text">{{ item.title }}</span>
            <span class="tooltip">{{ item.title }}</span>
          </div>
        </div>
        
        <!-- Feedback item at bottom -->
        <div class="feedback-container">
          <div 
            class="sidebar-item"
            @click="feedbackOverlay()"
          >
            <v-icon color="grey-darken-1" class="item-icon">{{ navbarItems[navbarItems.length - 1].icon }}</v-icon>
            <span class="item-text">{{ navbarItems[navbarItems.length - 1].title }}</span>
            <span class="tooltip">{{ navbarItems[navbarItems.length - 1].title }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Main content area -->
    <v-main class="content-with-sidebar" @click="closeIfExpanded">
      <div v-if="userdataLoaded">
        <router-view />
      </div>
      <v-container v-else class="d-flex flex-row align-center justify-center" style="height: 100vh;">
        <v-row justify="center" align="center">
          <v-col class="text-center">
            <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="primary">
            </v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
      
      <!-- Modernized Feedback Dialog -->
      <v-dialog v-model="showOverlay" :width="feedbackSuccess ? '400px' : '600px'">
        <v-card rounded="lg" elevation="10" class="modern-dialog">
          <template v-if="feedbackSuccess">
            <v-card-text class="pa-8 text-center">
              <v-avatar color="success" size="76" class="mb-4">
                <v-icon color="white" size="36">mdi-check</v-icon>
              </v-avatar>
              <h3 class="text-h5 font-weight-bold mb-3">Thank You!</h3>
              <p class="text-subtitle-1 text-medium-emphasis">Your feedback has been submitted successfully.</p>
              <v-btn
                color="primary"
                class="mt-6"
                block
                @click="showOverlay = false"
              >
                Close
              </v-btn>
            </v-card-text>
          </template>
          
          <template v-else>
            <v-toolbar color="primary" dark class="px-4" flat>
              <v-tabs
                v-model="activeTab"
                background-color="transparent"
                grow
              >
                <v-tab value="feedback">
                  <v-icon left class="mr-2">mdi-message-text</v-icon>
                  Feedback
                </v-tab>
                <v-tab value="reportBug">
                  <v-icon left class="mr-2">mdi-bug</v-icon>
                  Report Bug
                </v-tab>
              </v-tabs>
              <v-btn icon @click="showOverlay = false" class="ml-auto">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            
            <v-card-text class="pa-5">
              <!-- Feedback Tab -->
              <div v-if="activeTab === 'feedback'" class="my-2">
                <v-select
                  v-model="satisfactionLevel"
                  :items="['Very Satisfied', 'Satisfied', 'Not Satisfied']"
                  label="Rate your experience"
                  variant="outlined"
                  color="primary"
                  density="comfortable"
                  prepend-inner-icon="mdi-emoticon-outline"
                  class="mb-4"
                ></v-select>
                
                <v-textarea
                  v-model="feedbackText"
                  label="Share your thoughts"
                  variant="outlined"
                  color="primary"
                  auto-grow
                  prepend-inner-icon="mdi-comment-text-outline"
                  rows="4"
                  class="mb-4"
                ></v-textarea>
                
                <v-btn
                  color="primary"
                  variant="elevated"
                  :loading="loading"
                  block
                  @click="submitFeedback"
                  height="48"
                  class="text-none"
                >
                  Submit Feedback
                </v-btn>
              </div>
              
              <!-- Bug Report Tab -->
              <div v-else-if="activeTab === 'reportBug'" class="my-2">
                <v-text-field
                  v-model="bugLocation"
                  label="Where did the bug happen?"
                  variant="outlined"
                  color="primary"
                  density="comfortable"
                  prepend-inner-icon="mdi-map-marker-outline"
                  class="mb-4"
                ></v-text-field>
                
                <v-textarea
                  v-model="bugDescription"
                  label="Describe the issue"
                  variant="outlined"
                  color="primary"
                  auto-grow
                  prepend-inner-icon="mdi-bug-outline"
                  rows="4"
                  class="mb-4"
                ></v-textarea>
                
                <v-btn
                  color="primary"
                  variant="elevated"
                  :loading="loading"
                  block
                  @click="submitBugReport"
                  height="48"
                  class="text-none"
                >
                  Submit Bug Report
                </v-btn>
              </div>
            </v-card-text>
          </template>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import { ref, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import instance from '../store/axios';
import { useUserStore } from '../store/store';

export default {
  setup() {
    const router = useRouter();
    const userdataLoaded = ref(false);
    const userStore = useUserStore();
    const firstName = ref('');
    const lastInitial = ref('');
    const userdata = ref({
      firstName: '',
      middleName: '',
      lastName: '',
      cf_search: [],
      cf_saved: [],
    });
    const showOverlay = ref(false);
    const activeTab = ref('feedback');
    const feedbackText = ref('');
    const bugLocation = ref('');
    const satisfactionLevel = ref('');
    const bugDescription = ref('');
    const lastName = ref('');
    const email = ref('');
    const message = ref('');
    const isFeedbackActive = ref(true);
    const feedbackSuccess = ref(false);
    const loading = ref(false);
    const expanded = ref(false);
    const isMobile = ref(false);

    // Profile dropdown items
    const profileItems = ref([
      { title: 'Profile', to: '/profile', icon: 'mdi-account-box' },
      { title: 'Settings', to: '/settings', icon: 'mdi-cog' },
      { title: 'Feedback', to: '/feedback', icon: 'mdi-message-reply-text' },
      { title: 'Sign Out', to: '/logoutSuccess', icon: 'mdi-logout' },
    ]);

    // Navigation items 
    const navbarItems = ref([
      { title: 'Dashboard', to: '/dashboard', icon: 'mdi-view-dashboard' },
      { title: 'New CareerFit', to: '/search', icon: 'mdi-briefcase-search' },
      { title: 'Saved CareerFits', to: '/saved', icon: 'mdi-bookmark' },
      { title: 'My Coaching', to: '/coaching', icon: 'mdi-school' },
      { title: 'Feedback', icon: 'mdi-comment-text-outline' },
    ]);

    // Toggle menu expanded state
    const toggleMenu = () => {
      expanded.value = !expanded.value;
    };

    // Close menu if clicked outside
    const closeIfExpanded = (event) => {
      // Check if the click was outside the sidebar
      if (expanded.value && !event.target.closest('.expandable-sidebar')) {
        expanded.value = false;
      }
    };
    
    // Close menu on mobile when clicking app container
    const closeIfMobile = () => {
      if (expanded.value && isMobile.value) {
        expanded.value = false;
      }
    };

    // Handle navigation
    const navigateTo = (path) => {
      router.push(path);
      expanded.value = false;
    };

    // Open feedback dialog
    const feedbackOverlay = () => {
      showOverlay.value = true;
      activeTab.value = 'feedback';
      expanded.value = false;
    };

    const handleItemClick = (title) => {
      if (title === 'Feedback') {
        feedbackOverlay();
      }
    };

    const updateTab = (value) => {
      isFeedbackActive.value = value === 'feedback';
    };

    watch(activeTab, (newValue) => {
      updateTab(newValue);
    });

    updateTab(activeTab.value);

    const submitFeedback = async () => {
      loading.value = true;
      const feedbackData = {
        satisfactionLevel: satisfactionLevel.value,
        feedbackText: feedbackText.value,
        firstName: userdata.value.firstName,
        lastName: userdata.value.lastName
      };
      await putData(feedbackData);
      loading.value = false;
      
      // Reset form data
      feedbackText.value = '';
      satisfactionLevel.value = '';
    };

    const submitBugReport = async () => {
      loading.value = true;
      const bugReportData = {
        bugLocation: bugLocation.value,
        bugDescription: bugDescription.value,
        firstName: userdata.value.firstName,
        lastName: userdata.value.lastName
      };
      await putData(bugReportData);
      loading.value = false;
      
      // Reset form data
      bugLocation.value = '';
      bugDescription.value = '';
    };

    const putData = async (data) => {
      try {
        await instance.put('/feedback', { 
          feedback: data
        });
        console.log('Data successfully submitted:', data);
        feedbackSuccess.value = true;
      } catch (error) {
        console.error('Error submitting data:', error);
        feedbackSuccess.value = false;
      }
    };

    const updateMobileView = () => {
      const newIsMobile = window.innerWidth <= 768;
      isMobile.value = newIsMobile;
      
      // Auto-collapse on mobile
      if (newIsMobile) {
        expanded.value = false;
      }
    };

    onMounted(async () => {
      const storedUserdata = sessionStorage.getItem('userdata');
      window.addEventListener("resize", updateMobileView);
      updateMobileView();

      // Listen for clicks to close the menu
      document.addEventListener('click', (event) => {
        const sidebarEl = document.querySelector('.expandable-sidebar');
        const menuButtonEl = document.querySelector('.v-app-bar button');
        
        if (expanded.value && 
            sidebarEl && 
            !sidebarEl.contains(event.target) && 
            menuButtonEl && 
            !menuButtonEl.contains(event.target)) {
          expanded.value = false;
        }
      });

      if (storedUserdata) {
        const parsedUserdata = JSON.parse(storedUserdata);
        userdata.value = parsedUserdata;
        firstName.value = userdata.value.firstName;
        lastInitial.value = userdata.value.lastName ? userdata.value.lastName[0] : '';
        userdataLoaded.value = true;
      } else {
        try {
          const response = await instance.get('profile', {
            headers: {
              'Content-Type': 'application/json'
            }
          });
          userdata.value = response.data;
          userStore.setSessionUserData(userdata.value);

          const cf_search = {
            selectedJobFamilies: userdata.value.cf_search['selectedJobFamilies[]'],
            selectedJobProspects: userdata.value.cf_search['selectedJobProspects[]'],
            salary: Number(userdata.value.cf_search.salary[0]),
            isHourly: userdata.value.cf_search.isHourly[0] === "true",
            includeRegulated: userdata.value.cf_search.includeRegulated[0] === "true",
            timeToFirstJob: Number(userdata.value.cf_search.timeToFirstJob[0])
          };
          userStore.setCfSearch(cf_search);
          userStore.setCfSaved(userdata.value.cf_saved);

          firstName.value = userdata.value.firstName;
          lastInitial.value = userdata.value.lastName ? userdata.value.lastName[0] : '';
          userdataLoaded.value = true;
        } catch (error) {
          console.error('Error:', error);
        }
      }
    });

    return {
      profileItems,
      firstName,
      lastInitial,
      navbarItems,
      expanded,
      userdataLoaded,
      showOverlay,
      lastName,
      email,
      message,
      activeTab,
      bugDescription,
      satisfactionLevel,
      bugLocation,
      feedbackText,
      isFeedbackActive,
      feedbackSuccess,
      loading,
      updateTab,
      toggleMenu,
      navigateTo,
      feedbackOverlay,
      handleItemClick,
      submitFeedback,
      submitBugReport,
      putData,
      updateMobileView,
      isMobile,
      closeIfExpanded,
      closeIfMobile
    };
  },
};
</script>

<style scoped>
/* Expandable sidebar styles */
.expandable-sidebar {
  position: fixed;
  top: 64px; /* Adjust based on your app bar height */
  left: 0;
  height: calc(100vh - 64px);
  width: 64px;
  background-color: white;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  transition: width 0.3s ease;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.expandable-sidebar.expanded {
  width: 240px;
}

.sidebar-content {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.nav-items {
  flex-grow: 1;
}

.feedback-container {
  margin-bottom: 16px;
}

.sidebar-item {
  height: 48px;
  margin: 4px 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: background-color 0.2s ease;
  overflow: hidden;
}

.sidebar-item:hover {
  background-color: rgba(66, 165, 245, 0.1);
}

.sidebar-item.active {
  background-color: rgba(66, 165, 245, 0.2);
}

.item-icon {
  min-width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-item.active .item-icon {
  color: var(--v-primary-base) !important;
}

.item-text {
  flex-grow: 1;
  white-space: nowrap;
  opacity: 0;
  transform: translateX(-20px);
  transition: all 0.3s ease;
}

.expandable-sidebar.expanded .item-text {
  opacity: 1;
  transform: translateX(0);
}

.tooltip {
  position: absolute;
  left: 64px;
  background-color: rgba(33, 33, 33, 0.9);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  white-space: nowrap;
  z-index: 120;
  pointer-events: none;
}

.sidebar-item:hover .tooltip {
  opacity: 1;
  visibility: visible;
}

.expandable-sidebar.expanded .tooltip {
  opacity: 0;
  visibility: hidden;
}

/* Content area - now fixed padding */
.content-with-sidebar {
  padding-left: 64px !important;
  transition: padding-left 0.3s ease;
}

/* Mobile overlay */
.menu-overlay {
  position: fixed;
  top: 64px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 64px);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  transition: opacity 0.3s ease;
}

/* Modern dialog styles */
.modern-dialog {
  overflow: hidden;
  border-radius: 16px;
}

/* Mobile styles */
@media (max-width: 768px) {
  .expandable-sidebar {
    width: 0;
    opacity: 0;
  }
  
  .expandable-sidebar.expanded {
    width: 240px;
    opacity: 1;
    z-index: 101;
  }
  
  .content-with-sidebar {
    padding-left: 0 !important;
  }
}
</style>