<template>
  <div class="wizard-container">
    <v-card class="wizard-card" elevation="10">
      <div class="wizard-header">
        <h1 class="wizard-title">Find Your Career Path</h1>
        <p class="wizard-subtitle">Discover opportunities tailored to your skills and preferences</p>
      </div>
      
      <v-stepper v-model="step" :items="stepper" show-actions class="custom-stepper">
        <template v-slot:actions="{ next, prev }">
          <div class="stepper-actions">
            <v-btn 
              color="secondary" 
              variant="outlined"
              @click="prev" 
              :disabled="step === 1" 
              class="action-btn"
              prepend-icon="mdi-arrow-left"
            >
              Back
            </v-btn>
            <v-btn 
              color="primary" 
              variant="elevated"
              @click="step === 4 ? submit() : next()" 
              class="action-btn"
              :loading="loading"
              append-icon="mdi-arrow-right"
            >
              {{ step === 4 ? 'Find Matches' : 'Continue' }}
            </v-btn>
          </div>
        </template>

        <!-- Step 1: Career Families -->
        <template v-slot:[`item.1`]>
          <div class="wizard-step-container">
            <h2 class="step-title">
              <v-icon color="primary" class="step-icon">mdi-briefcase-variant</v-icon>
              Career Families
            </h2>
            <p class="step-description">Select the career areas that interest you the most</p>
            
            <div class="option-chips">
              <v-chip-group v-model="selectedJobFamilies" column multiple>
                <v-chip
                  v-for="(family, index) in jobFamilies"
                  :key="index"
                  filter
                  variant="outlined"
                >
                  {{ family }}
                </v-chip>
              </v-chip-group>
            </div>
            
            <v-switch
              v-model="selectAllJobFamilies"
              label="Select All Career Families"
              color="primary"
              class="select-all-switch"
              density="comfortable"
              @change="toggleSelectAllJobFamilies"
            ></v-switch>
          </div>
        </template>

        <!-- Step 2: Income Preferences -->
        <template v-slot:[`item.2`]>
          <div class="wizard-step-container">
            <h2 class="step-title">
              <v-icon color="primary" class="step-icon">mdi-currency-usd</v-icon>
              Income Preferences
            </h2>
            <p class="step-description">Tell us about your salary expectations</p>
            
            <div class="salary-display">
              <v-chip color="primary" variant="elevated" size="x-large" class="salary-chip">
                {{ isHourly ? `CAD $${(salary / 2080).toFixed(0)}/hr` : `CAD $${salary.toLocaleString()}/year` }}
              </v-chip>
            </div>
            
            <div class="salary-slider">
              <v-slider 
                v-model="salary" 
                step="1000" 
                :max="150000" 
                :min="20000"
                color="primary"
                track-color="primary-lighten-3"
                thumb-label="always"
              >
                <template v-slot:prepend>
                  <v-icon>mdi-cash-minus</v-icon>
                </template>
                <template v-slot:append>
                  <v-icon>mdi-cash-plus</v-icon>
                </template>
              </v-slider>
            </div>
            
            <v-switch 
              color="primary" 
              v-model="isHourly" 
              label="Show as hourly rate" 
              class="rate-switch"
              density="comfortable"
            ></v-switch>
          </div>
        </template>

        <!-- Step 3: Job Prospects -->
        <template v-slot:[`item.3`]>
          <div class="wizard-step-container">
            <h2 class="step-title">
              <v-icon color="primary" class="step-icon">mdi-chart-line</v-icon>
              Job Prospects
            </h2>
            <p class="step-description">Select the job market conditions you're looking for</p>
            
            <div class="option-chips">
              <v-chip-group v-model="selectedJobProspects" multiple>
                <v-chip
                  v-for="(prospect, index) in jobProspects" 
                  :key="index" 
                  :value="prospect"
                  filter
                  variant="outlined"
                >
                  {{ prospect }}
                </v-chip>
              </v-chip-group>
            </div>
            
            <v-switch
              v-model="selectAllJobProspects"
              label="Select All Prospects"
              color="primary"
              class="select-all-switch"
              density="comfortable"
              @change="toggleSelectAllJobProspects"
            ></v-switch>
          </div>
        </template>

        <!-- Step 4: Regulations -->
        <template v-slot:[`item.4`]>
          <div class="wizard-step-container">
            <h2 class="step-title">
              <v-icon color="primary" class="step-icon">mdi-certificate</v-icon>
              Regulatory Requirements
            </h2>
            <p class="step-description">Tell us about your regulatory preferences</p>
            
            <v-card class="regulation-card" variant="outlined">
              <v-card-text>
                <v-switch 
                  v-model="includeRegulated" 
                  color="primary" 
                  label="Include Regulated Professions" 
                  class="regulation-switch"
                  density="comfortable"
                ></v-switch>
                
                <div class="time-slider-container">
                  <v-fade-transition>
                    <div v-if="includeRegulated">
                      <p class="slider-label">Time willing to wait for first job:</p>
                      <v-slider 
                        v-model="timeToFirstJob" 
                        class="time-slider" 
                        step="1" 
                        show-ticks="always" 
                        thumb-label="always" 
                        :ticks="tickLabels" 
                        tick-size="4" 
                        :max="48" 
                        :min="0"
                        color="primary"
                      ></v-slider>
                    </div>
                  </v-fade-transition>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </template>

        <!-- Results -->
        <template v-slot:[`item.5`]>
          <div class="wizard-step-container">
            <h2 class="step-title">
              <v-icon color="success" class="step-icon">mdi-check-circle</v-icon>
              Results
            </h2>
            <pre>{{ JSON.stringify(response, null, 2) }}</pre>
          </div>
        </template>
      </v-stepper>
    </v-card>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import router from '../router/router';
import { useUserStore } from '../store/store';
import { jobProspects, stepper, jobFamilies, preferences } from '../config/forms.js';

export default {
  setup() {
    const step = ref(1);
    const selectedJobFamilies = ref([]);
    const includeRegulated = ref(true);
    const salary = ref(20000);
    const isHourly = ref(false);
    const selectedJobProspects = ref([]);
    const timeToFirstJob = ref(0);
    const loading = ref(false);
    const response = ref(null);

    // Select All switches and previous selections
    const selectAllJobFamilies = ref(false);
    const selectAllJobProspects = ref(false);
    const previousJobFamilies = ref([]);
    const previousJobProspects = ref([]);

    const userStore = useUserStore();

    const nextButtonLabel = computed(() => step.value === 4 ? 'Submit' : 'Next');
    const sessionData = sessionStorage.getItem('cf_search');

    onMounted(() => {
      // Check if user data exists in the store
      if (sessionData) {
        const data = JSON.parse(sessionData);
        selectedJobFamilies.value = data.selectedJobFamilies.map(jobFamily => jobFamilies.indexOf(jobFamily));
        selectedJobProspects.value = data.selectedJobProspects;
        salary.value = data.salary;
        isHourly.value = data.isHourly;
        includeRegulated.value = data.includeRegulated;
        timeToFirstJob.value = data.timeToFirstJob;
      }
    });

    // Toggle Select All for Job Families
    const toggleSelectAllJobFamilies = () => {
      if (selectAllJobFamilies.value) {
        previousJobFamilies.value = [...selectedJobFamilies.value];
        selectedJobFamilies.value = jobFamilies.map((_, index) => index);
      } else {
        selectedJobFamilies.value = [...previousJobFamilies.value];
      }
    };

    // Toggle Select All for Job Prospects
    const toggleSelectAllJobProspects = () => {
      if (selectAllJobProspects.value) {
        previousJobProspects.value = [...selectedJobProspects.value];
        selectedJobProspects.value = jobProspects.map((prospect) => prospect);
      } else {
        selectedJobProspects.value = [...previousJobProspects.value];
      }
    };

    const submit = async () => {
      loading.value = true;
      const data = {
        selectedJobFamilies: selectedJobFamilies.value.map(index => jobFamilies[index]),
        selectedJobProspects: Object.values(selectedJobProspects.value),
        salary: salary.value,
        isHourly: isHourly.value,
        includeRegulated: includeRegulated.value,
        timeToFirstJob: timeToFirstJob.value,
      };
      userStore.setCfSearch(data);
      userStore.setCfResults([]);
      
      // Simulate API response for demo
      setTimeout(() => {
        response.value = { success: true, count: 42 };
        loading.value = false;
        router.push({ path: '/results' });
      }, 1000);
    };

    return {
      step,
      selectedJobFamilies,
      selectedJobProspects,
      salary,
      isHourly,
      nextButtonLabel,
      submit,
      stepper,
      jobFamilies,
      jobProspects,
      preferences,
      includeRegulated,
      timeToFirstJob,
      tickLabels: {
        0: 'ASAP',
        12: '1 Year',
        24: '2 Years',
        36: '3+ years',
      },
      selectAllJobFamilies,
      toggleSelectAllJobFamilies,
      selectAllJobProspects,
      toggleSelectAllJobProspects,
      loading,
      response
    };
  },
};
</script>

<style scoped>
/* Add a container element that fills the content area */
.wizard-container {
  width: 100%;
  max-width: 1000px;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 140px); /* Subtract header/footer/padding */
}

.wizard-card {
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1) !important;
  height: fit-content;
}

.wizard-header {
  background: linear-gradient(135deg, #1a237e 0%, #42a5f5 100%);
  color: white;
  text-align: center;
  padding: 2.5rem 2rem;
}

.wizard-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  letter-spacing: -0.5px;
}

.wizard-subtitle {
  font-size: 1.2rem;
  font-weight: 300;
  opacity: 0.9;
}

.custom-stepper {
  background: transparent;
}

.stepper-actions {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  background: #f8fafc;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.action-btn {
  min-width: 140px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.wizard-step-container {
  padding: 2rem 2.5rem 2rem; /* Updated padding */
}

.step-title {
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #1a237e;
  display: flex;
  align-items: center;
}

.step-icon {
  margin-right: 0.5rem;
  font-size: 1.75rem;
}

.step-description {
  font-size: 1.1rem;
  color: #546e7a;
  margin-bottom: 2rem;
}

.option-chips {
  margin-bottom: 2rem;
}

.select-all-switch {
  margin-top: 1rem;
}

.salary-display {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.salary-chip {
  font-size: 1.5rem;
  padding: 1rem 2rem;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.salary-slider {
  padding: 0 1rem;
  margin-bottom: 1.5rem;
}

.rate-switch {
  margin-top: 1rem;
}

.regulation-card {
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 16px;
  background-color: #f8f9fc;
}

.time-slider-container {
  min-height: 100px;
  padding-top: 1rem;
}

.slider-label {
  font-size: 1rem;
  color: #455a64;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

/* Media queries for better responsive behavior */
@media (max-width: 959px) {
  .wizard-container {
    margin: 1rem auto;
    min-height: calc(100vh - 180px);
  }
  
  .wizard-title {
    font-size: 2rem;
  }
  
  .wizard-step-container {
    padding: 1.5rem 1.5rem 2rem;
  }
  
  .step-title {
    font-size: 1.5rem;
  }
}

@media (max-width: 599px) {
  .wizard-container {
    margin: 0.5rem auto;
    min-height: calc(100vh - 150px);
  }
  
  .wizard-header {
    padding: 2rem 1rem;
  }
  
  .wizard-title {
    font-size: 1.75rem;
  }
  
  .wizard-step-container {
    padding: 1rem 1rem 1.5rem;
  }
  
  .stepper-actions {
    padding: 1rem;
  }
  
  .action-btn {
    min-width: auto;
  }
  
  .salary-chip {
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
  }
}
</style>