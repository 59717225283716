<template>
  <v-app>
    <v-main class="form-wizard-main">
      <div class="wizard-container">
        <v-card class="wizard-card" elevation="10">
          <div class="wizard-header">
            <h1 class="wizard-title">Find Your Career Path</h1>
            <p class="wizard-subtitle">Discover opportunities tailored to your skills and preferences</p>
          </div>
          
          <v-stepper v-model="step" :items="stepper" show-actions class="custom-stepper">
            <template v-slot:actions="{ next, prev }">
              <div class="stepper-actions">
                <v-btn 
                  color="secondary" 
                  variant="outlined"
                  @click="prev" 
                  :disabled="step === 1" 
                  class="action-btn"
                  prepend-icon="mdi-arrow-left"
                >
                  Back
                </v-btn>
                <v-btn 
                  color="primary" 
                  variant="elevated"
                  @click="step === 4 ? submit() : next()" 
                  class="action-btn"
                  :loading="loading"
                  append-icon="mdi-arrow-right"
                >
                  {{ step === 4 ? 'Find Matches' : 'Continue' }}
                </v-btn>
              </div>
            </template>
            
            <!-- Step 1: Career Families -->
            <template v-slot:[`item.1`]>
              <div class="wizard-step-container">
                <h2 class="step-title">
                  <v-icon color="primary" class="step-icon">mdi-briefcase-variant</v-icon>
                  Career Families
                </h2>
                <p class="step-description">Select the career areas that interest you the most</p>
                
                <div class="option-chips">
                  <v-chip-group v-model="selectedJobFamilies" column multiple>
                    <v-chip
                      v-for="(family, index) in jobFamilies" 
                      :key="index"
                      filter
                      variant="outlined"
                      class="selection-chip"
                    >
                      {{ family }}
                    </v-chip>
                  </v-chip-group>
                </div>
                
                <v-switch
                  v-model="selectAllJobFamilies"
                  label="Select All Career Families"
                  color="primary"
                  class="select-all-switch"
                  density="comfortable"
                  @change="toggleSelectAllJobFamilies"
                ></v-switch>
              </div>
            </template>
            
            <!-- Step 2: Income Preferences -->
            <template v-slot:[`item.2`]>
              <div class="wizard-step-container">
                <h2 class="step-title">
                  <v-icon color="primary" class="step-icon">mdi-currency-usd</v-icon>
                  Income Preferences
                </h2>
                <p class="step-description">Tell us about your salary expectations</p>
                
                <div class="salary-display">
                  <v-chip color="primary" variant="elevated" size="x-large" class="salary-chip">
                    {{ isHourly ? `CAD $${(salary / 2080).toFixed(0)}/hr` : `CAD $${salary.toLocaleString()}/year` }}
                  </v-chip>
                </div>
                
                <div class="salary-slider">
                  <v-slider 
                    v-model="salary" 
                    step="1000" 
                    :max="150000" 
                    :min="20000"
                    color="primary"
                    track-color="primary-lighten-3"
                    thumb-label="always"
                  >
                    <template v-slot:prepend>
                      <v-icon>mdi-cash-minus</v-icon>
                    </template>
                    <template v-slot:append>
                      <v-icon>mdi-cash-plus</v-icon>
                    </template>
                  </v-slider>
                </div>
                
                <v-switch 
                  color="primary" 
                  v-model="isHourly" 
                  label="Show as hourly rate" 
                  class="rate-switch"
                  density="comfortable"
                ></v-switch>
              </div>
            </template>
            
            <!-- Step 3: Job Prospects -->
            <template v-slot:[`item.3`]>
              <div class="wizard-step-container">
                <h2 class="step-title">
                  <v-icon color="primary" class="step-icon">mdi-chart-line</v-icon>
                  Job Prospects
                </h2>
                <p class="step-description">Select the job market conditions you're looking for</p>
                
                <div class="option-chips">
                  <v-chip-group v-model="selectedJobProspects" multiple>
                    <v-chip
                      v-for="(prospect, index) in jobProspects" 
                      :key="index" 
                      :value="prospect"
                      filter
                      variant="outlined"
                      class="selection-chip"
                    >
                      {{ prospect }}
                    </v-chip>
                  </v-chip-group>
                </div>
                
                <v-switch
                  v-model="selectAllJobProspects"
                  label="Select All Prospects"
                  color="primary"
                  class="select-all-switch"
                  density="comfortable"
                  @change="toggleSelectAllJobProspects"
                ></v-switch>
              </div>
            </template>
            
            <!-- Step 4: Regulations -->
            <template v-slot:[`item.4`]>
              <div class="wizard-step-container">
                <h2 class="step-title">
                  <v-icon color="primary" class="step-icon">mdi-certificate</v-icon>
                  Regulatory Requirements
                </h2>
                <p class="step-description">Tell us about your regulatory preferences</p>
                
                <v-card class="regulation-card" variant="outlined">
                  <v-card-text>
                    <v-switch 
                      v-model="includeRegulated" 
                      color="primary" 
                      label="Include Regulated Professions" 
                      class="regulation-switch"
                      density="comfortable"
                    ></v-switch>
                    
                    <div class="time-slider-container">
                      <v-fade-transition>
                        <div v-if="includeRegulated">
                          <p class="slider-label">Time willing to wait for first job:</p>
                          <v-slider 
                            v-model="timeToFirstJob" 
                            class="time-slider" 
                            step="1" 
                            show-ticks="always" 
                            thumb-label="always" 
                            :ticks="tickLabels" 
                            tick-size="4" 
                            :max="48" 
                            :min="0"
                            color="primary"
                          ></v-slider>
                        </div>
                      </v-fade-transition>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </template>
            
            <!-- Results -->
            <template v-slot:[`item.5`]>
              <div class="wizard-step-container">
                <h2 class="step-title">
                  <v-icon color="success" class="step-icon">mdi-check-circle</v-icon>
                  Results
                </h2>
                <pre>{{ JSON.stringify(response, null, 2) }}</pre>
              </div>
            </template>
          </v-stepper>
        </v-card>
      </div>
      
      <!-- Results Dialog -->
      <v-dialog v-model="showOverlay" :width="contactSuccess ? '450px' : '900px'" transition="dialog-bottom-transition">
        <v-card class="results-card">
          <v-container>
            <v-row justify="end" no-gutters class="dialog-close-row">
              <v-col cols="12" class="text-right">
                <v-btn icon variant="text" @click="showOverlay = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            
            <!-- Success message -->
            <v-row class="align-center justify-center" v-if="contactSuccess">
              <v-col cols="12" class="success-message">
                <div class="success-content">
                  <div class="success-icon-container">
                    <v-icon class="success-icon" color="success">mdi-check-circle</v-icon>
                  </div>
                  <h2 class="success-title">Thank You!</h2>
                  <p class="success-text">Your information has been submitted successfully. We'll be in touch soon.</p>
                  <v-btn color="primary" variant="tonal" @click="showOverlay = false" class="mt-6">
                    Close
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            
            <!-- Contact form -->
            <v-row class="align-center justify-center" v-else>
              <v-col cols="12" md="4" class="results-counter-col">
                <div class="results-counter">
                  <h3 class="counter-title">Careers Found</h3>
                  <div class="counter-number">{{ animatedCounter }}</div>
                  <p class="counter-text">career matches based on your preferences</p>
                </div>
              </v-col>
              

              
              <v-col cols="12" md="8">
                <div class="contact-form-container">
                  <h3 class="form-title">Find Your Potential!</h3>
                  <p class="form-subtitle">Fill out your details and we'll send you a complete breakdown of your career matches</p>
                  
                  <v-form ref="form" class="contact-form" v-model="isFormValid">
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field 
                          v-model="firstName" 
                          label="First Name" 
                          variant="outlined"
                          prepend-inner-icon="mdi-account"
                          :rules="[v => !!v || '']"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field 
                          v-model="lastName" 
                          label="Last Name" 
                          variant="outlined"
                          prepend-inner-icon="mdi-account"
                          :rules="[v => !!v || '']"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    
                    <v-text-field 
                      v-model="email" 
                      label="Email Address" 
                      variant="outlined"
                      prepend-inner-icon="mdi-email"
                      :rules="emailRules"
                      required
                    ></v-text-field>
                    
                    <v-textarea 
                      v-model="message" 
                      label="Any specific questions or interests?" 
                      variant="outlined"
                      prepend-inner-icon="mdi-message-text"
                      rows="3"
                      :rules="[v => !!v || '']"
                      required
                    ></v-textarea>
                    
                    <v-btn 
                      :loading="btnloading" 
                      block 
                      variant="elevated" 
                      size="large" 
                      color="primary" 
                      @click="submitContact"
                      class="submit-btn"
                      :disabled="!isFormValid"
                    >
                      Contact Us
                      <v-icon class="ms-2">mdi-send</v-icon>
                    </v-btn>
                  </v-form>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
  </template>
  
  <script>
  // Your script remains largely unchanged
  import { ref, computed } from 'vue';
  import instance from '../store/axios';
  import { jobProspects, stepper, jobFamilies, preferences } from '../config/forms.js';
  
  export default {
    setup() {
      // All your existing setup logic remains the same
      const step = ref(1);
      const selectedJobFamilies = ref([]);
      const includeRegulated = ref(true);
      const salary = ref(20000);
      const isHourly = ref(false);
      const selectedJobProspects = ref([]);
      const timeToFirstJob = ref(0);
      const loading = ref(false);
      const btnloading = ref(false);
      const response = ref(null);
      const showOverlay = ref(false);
      const showForm = ref(false);
      const responseCount = ref(0);
      const animatedCounter = ref(0);
      const firstName = ref('');
      const lastName = ref('');
      const email = ref('');
      const message = ref('');
      const contactSuccess = ref(false);

      // Add form validation model
      const isFormValid = ref(false);
    
      // Only keep email validation rules with a message
      const requiredRule = [v => !!v || ''];
      const emailRules = [
        v => !!v || '',  // Required but no message
        v => /.+@.+\..+/.test(v) || 'Email must be valid'
      ];
  
      const selectAllJobFamilies = ref(false);
      const selectAllJobProspects = ref(false);
  
      const nextButtonLabel = computed(() => step.value === 4 ? 'Submit' : 'Next');
  
      const animateCounter = (finalCount) => {
        let currentCount = 0;
        clearInterval(animationInterval.value);
        animationInterval.value = setInterval(() => {
          currentCount += Math.ceil(finalCount / 100);
          if (currentCount >= finalCount) {
            currentCount = finalCount;
            clearInterval(animationInterval.value);
          }
          animatedCounter.value = currentCount;
        }, 20);
      };
  
      const animationInterval = ref(null);
  
      // Toggle Select All Job Families
      const toggleSelectAllJobFamilies = () => {
        if (selectAllJobFamilies.value) {
          selectedJobFamilies.value = jobFamilies.map((_, index) => index);
        } else {
          selectedJobFamilies.value = [];
        }
      };
  
      // Toggle Select All Job Prospects
      const toggleSelectAllJobProspects = () => {
        if (selectAllJobProspects.value) {
          selectedJobProspects.value = jobProspects.map((prospect) => prospect);
        } else {
          selectedJobProspects.value = [];
        }
      };
  
      const submit = async () => {
        loading.value = true;
        showOverlay.value = true;
        animatedCounter.value = 0;
        animateCounter(100);
  
        const data = {
          selectedJobFamilies: selectedJobFamilies.value.map(index => jobFamilies[index]),
          selectedJobProspects: selectedJobProspects.value,
          salary: salary.value,
          isHourly: isHourly.value,
          includeRegulated: includeRegulated.value,
          timeToFirstJob: timeToFirstJob.value,
        };
  
        console.log('Data:', JSON.stringify(data));
  
        try {
          const res = await instance.get('public', {
            params: data,
            headers: {
              'Content-Type': 'application/json'
            }
          });
  
          response.value = res.data;
  
          console.log('API Response:', JSON.stringify(response.value, null, 2));
  
          if (response.value && response.value.count !== undefined) {
            responseCount.value = response.value.count;
            animateCounter(responseCount.value);
          }
  
          console.log('Response Count:', responseCount.value);
        } catch (error) {
          console.error('Error:', error);
        } finally {
          loading.value = false;
        }
      };
  
      const submitContact = async () => {
      // Check if the form is valid before submission
      if (!isFormValid.value) {
        return; // Don't proceed if form is invalid
      }
      
      btnloading.value = true; // Start loading
      const contactdata = {
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
        message: message.value,
      };

      await putData(contactdata);

      btnloading.value = false; // Stop loading
    };
  
      const putData = async (data) => {
        try {
          await instance.put('public/contact', { 
            contactinfo : data
          });
          console.log('Data successfully submitted:', data);
          contactSuccess.value = true;
        } catch (error) {
          console.error('Error submitting data:', error);
          contactSuccess.value = false;
        }
      };
  
      return {
        // Return all your existing refs and methods
        step,
        selectedJobFamilies,
        selectedJobProspects,
        salary,
        isHourly,
        nextButtonLabel,
        submit,
        stepper,
        jobFamilies,
        jobProspects,
        preferences,
        includeRegulated,
        timeToFirstJob,
        tickLabels: {
          0: 'ASAP',
          12: '1 Year',
          24: '2 Years',
          36: '3+ years',
        },
        loading,
        response,
        showOverlay,
        showForm,
        responseCount,
        animatedCounter,
        firstName,
        lastName,
        email,
        message,
        btnloading,
        contactSuccess,
        viewDetails: () => { showForm.value = true; },
        submitContact,
        selectAllJobFamilies,
        toggleSelectAllJobFamilies,
        selectAllJobProspects,
        toggleSelectAllJobProspects,
        // Add the new validation properties
        isFormValid,
        requiredRule,
        emailRules
      };
    },
  };
  </script>
  
  <style scoped>
  .form-wizard-main {
    min-height: 100vh;
    background: linear-gradient(135deg, #f5f7fa 0%, #e4ecfb 100%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .wizard-container {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .wizard-card {
    border-radius: 24px;
    overflow: hidden;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1) !important;
  }
  
  .wizard-header {
    background: linear-gradient(135deg, #1a237e 0%, #42a5f5 100%);
    color: white;
    text-align: center;
    padding: 2.5rem 2rem;
  }
  
  .wizard-title {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    letter-spacing: -0.5px;
  }
  
  .wizard-subtitle {
    font-size: 1.2rem;
    font-weight: 300;
    opacity: 0.9;
  }
  
  .custom-stepper {
    background: transparent;
  }
  
  .stepper-actions {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 2rem;
    background: #f8fafc;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  .action-btn {
    min-width: 140px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  
  .wizard-step-container {
    padding: 2rem 2.5rem 0rem; /* Reduced bottom padding from 3rem to 1.5rem */
  }
  
  .step-title {
    font-size: 1.75rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #1a237e;
    display: flex;
    align-items: center;
  }
  
  .step-icon {
    margin-right: 0.5rem;
    font-size: 1.75rem;
  }
  
  .step-description {
    font-size: 1.1rem;
    color: #546e7a;
    margin-bottom: 2rem;
  }
  
  .option-chips {
    margin-bottom: 2rem;
  }
  
  
  .select-all-switch {
    margin-top: 1rem;
  }
  
  .salary-display {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }
  
  .salary-chip {
    font-size: 1.5rem;
    padding: 1rem 2rem;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  
  .salary-slider {
    padding: 0 1rem;
    margin-bottom: 1.5rem;
  }
  
  .rate-switch {
    margin-top: 1rem;
  }
  
  .regulation-card {
    padding: 1rem;
    margin-top: 1rem;
    border-radius: 16px;
    background-color: #f8f9fc;
  }
  
  .time-slider-container {
    min-height: 100px;
    padding-top: 1rem;
  }
  
  .slider-label {
    font-size: 1rem;
    color: #455a64;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
  }
  
  .results-card {
    border-radius: 24px;
    overflow: hidden;
  }
  
  .dialog-close-row {
    padding: 1rem;
  }
  
  .results-counter-col {
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .results-counter {
    text-align: center;
    padding: 2rem 1rem;
  }
  
  .counter-title {
    font-size: 1.25rem;
    color: #455a64;
    margin-bottom: 1rem;
  }
  
  .counter-number {
    font-size: 4rem;
    font-weight: 700;
    color: #1a237e;
    margin-bottom: 0.5rem;
  }
  
  .counter-text {
    color: #78909c;
    font-size: 1rem;
  }
  
  .contact-form-container {
    padding: 2rem 1rem;
  }
  
  .form-title {
    font-size: 1.75rem;
    font-weight: 600;
    color: #1a237e;
    margin-bottom: 0.5rem;
  }
  
  .form-subtitle {
    color: #546e7a;
    margin-bottom: 2rem;
  }
  
  .submit-btn {
    margin-top: 1.5rem;
    padding: 0.75rem;
    font-weight: 600;
    font-size: 1.1rem;
    letter-spacing: 0.5px;
  }
  
  .success-message {
    padding: 3rem 1.5rem;
    text-align: center;
  }
  
  .success-content {
    max-width: 350px;
    margin: 0 auto;
  }
  
  .success-icon-container {
    margin-bottom: 1.5rem;
  }
  
  .success-icon {
    font-size: 5rem;
    color: #4caf50;
  }
  
  .success-title {
    font-size: 2rem;
    font-weight: 600;
    color: #1a237e;
    margin-bottom: 1rem;
  }
  
  .success-text {
    font-size: 1.1rem;
    color: #546e7a;
    line-height: 1.6;
  }
  
  /* Media queries for better responsive behavior */
  @media (max-width: 959px) {
    .wizard-title {
      font-size: 2rem;
    }
    
    .wizard-step-container {
      padding: 1.5rem 1.5rem 2.5rem;
    }
    
    .step-title {
      font-size: 1.5rem;
    }
    
    .counter-number {
      font-size: 3rem;
    }
  }
  
  @media (max-width: 599px) {
    .wizard-header {
      padding: 2rem 1rem;
    }
    
    .wizard-title {
      font-size: 1.75rem;
    }
    
    .wizard-step-container {
      padding: 1rem 1rem 2rem;
    }
    
    .stepper-actions {
      padding: 1rem;
    }
    
    .action-btn {
      min-width: auto;
    }
    
    .salary-chip {
      font-size: 1.25rem;
      padding: 0.75rem 1.5rem;
    }
  }
  </style>