<template>
  <div>
    <!-- Loading state -->
    <v-container v-if="isLoading" class="d-flex flex-row align-center justify-center" style="height: 100vh;">
      <v-row justify="center" align="center">
        <v-col class="text-center">
          <v-progress-circular
            indeterminate
            size="70"
            width="7"
            color="primary">
          </v-progress-circular>
        </v-col>
      </v-row>
    </v-container>

    <!-- Dashboard content -->
    <v-container v-else fluid class="dashboard-container">
      <v-row justify="center">
        <v-col cols="12" lg="10" xl="8">
          
          <!-- Welcome Header Section -->
          <v-card class="welcome-card mb-8" elevation="3">
            <v-row no-gutters>
              <v-col cols="12" md="8" class="welcome-content pa-8">
                <h1 class="text-h3 font-weight-bold text-primary welcome-title">
                  {{ username ? `Welcome back, ${username}` : 'Welcome to CareerFit.ca!' }}
                </h1>
                <p class="text-subtitle-1 welcome-subtitle mt-4">
                  View your enrolled sessions, saved jobs and more below!
                </p>
                <v-btn 
                  color="primary" 
                  class="mt-6" 
                  size="large" 
                  rounded 
                  elevation="2" 
                  to="/search" 
                  prepend-icon="mdi-magnify"
                >
                  Start New CareerFit Search
                </v-btn>
              </v-col>
              <v-col cols="12" md="4" class="dashboard-stats-col">
                <div class="dashboard-stats">
                  <v-icon color="primary" size="48" class="stats-icon mb-2">mdi-bookmark-box-multiple</v-icon>
                  <div class="stats-number">{{ totalCareerFits.value || 0 }}</div>
                  <div class="stats-label">Saved CareerFits</div>
                </div>
              </v-col>
            </v-row>
          </v-card>
          
          <!-- Last Search Section -->
          <section class="dashboard-section">
            <div class="section-header">
              <h2 class="section-title">
                <v-icon color="primary" class="section-icon">mdi-history</v-icon>
                Your Last Search
              </h2>
              <div class="section-actions" v-if="totalSelectedJobFamilies !== 0">
                <v-btn variant="text" color="primary" class="mr-2" prepend-icon="mdi-refresh" @click="$router.push('/search')">
                  New Search
                </v-btn>
                <v-btn color="primary" :disabled="totalSelectedJobFamilies === 0" prepend-icon="mdi-eye" @click="$router.push('/results')">
                  View Results
                </v-btn>
              </div>
            </div>
            
            <v-card v-if="totalSelectedJobFamilies !== 0" class="search-card" elevation="2">
              <v-card-text>
                <v-row class="search-details">
                  <v-col cols="12" sm="6" md="4" class="search-detail-item">
                    <div class="detail-label">
                      <v-icon color="primary" size="small" class="mr-2">mdi-briefcase-variant</v-icon>
                      Job Families
                    </div>
                    <div class="detail-value">{{ totalSelectedJobFamilies }}</div>
                  </v-col>
                  
                  <v-col cols="12" sm="6" md="4" class="search-detail-item">
                    <div class="detail-label">
                      <v-icon color="primary" size="small" class="mr-2">mdi-chart-line</v-icon>
                      Job Prospects
                    </div>
                    <div class="detail-value detail-chip-container">
                      <v-chip 
                        v-for="(prospect, index) in jobProspects" 
                        :key="index" 
                        size="small" 
                        color="primary" 
                        variant="outlined" 
                        class="detail-chip"
                      >
                        {{ prospect }}
                      </v-chip>
                    </div>
                  </v-col>
                  
                  <v-col cols="12" sm="6" md="4" class="search-detail-item">
                    <div class="detail-label">
                      <v-icon color="primary" size="small" class="mr-2">mdi-clock-time-four-outline</v-icon>
                      Time to First Job
                    </div>
                    <div class="detail-value">{{ formatTimeToFirstJob(timeToFirstJob) }}</div>
                  </v-col>
                  
                  <v-col cols="12" sm="6" md="4" class="search-detail-item">
                    <div class="detail-label">
                      <v-icon color="primary" size="small" class="mr-2">mdi-certificate-outline</v-icon>
                      Regulated
                    </div>
                    <div class="detail-value">
                      <v-chip size="small" :color="regulated ? 'success' : 'warning'" variant="flat">
                        {{ regulated ? 'Yes' : 'No' }}
                      </v-chip>
                    </div>
                  </v-col>
                  
                  <v-col cols="12" sm="6" md="4" class="search-detail-item">
                    <div class="detail-label">
                      <v-icon color="primary" size="small" class="mr-2">mdi-currency-usd</v-icon>
                      Min Salary
                    </div>
                    <div class="detail-value">{{ formatCurrency(salary) }}</div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            
            <v-card v-else class="search-card text-center py-8" elevation="2">
              <v-icon color="grey" size="48" class="mb-4">mdi-file-search-outline</v-icon>
              <p class="text-h6 text-grey">No CareerFit search found</p>
              <p class="text-subtitle-2 text-grey mb-6">Complete a search to see your results here</p>
              <v-btn color="primary" to="/search" prepend-icon="mdi-magnify">Start New Search</v-btn>
            </v-card>
          </section>
          
          <!-- Enrolled CareerFit Coaching Section -->
          <section class="dashboard-section">
            <div class="section-header">
              <h2 class="section-title">
                <v-icon color="primary" class="section-icon">mdi-school</v-icon>
                Enrolled Coaching Sessions
              </h2>
              <div class="section-actions">
                <v-btn color="primary" to="/coaching" prepend-icon="mdi-application">Browse Courses</v-btn>
              </div>
            </div>
            
            <v-card class="coaching-card" elevation="2">
              <v-card-text class="pa-0">
                <v-table>
                  <thead>
                    <tr>
                      <th v-for="header in headers" :key="header.key" class="text-left">
                        {{ header.title }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in courses" :key="index" class="coaching-row">
                      <td>{{ item.course }}</td>
                      <td>
                        <v-chip
                          size="small"
                          :color="getStatusColor(item.status)"
                          variant="flat"
                        >
                          {{ item.status }}
                        </v-chip>
                      </td>
                      <td>{{ item.date }}</td>
                      <td>{{ item.time }}</td>
                    </tr>
                    <tr v-if="courses.length === 0">
                      <td colspan="4" class="text-center py-8 text-grey">
                        <v-icon color="grey" size="36" class="mb-2 d-block mx-auto">mdi-school-outline</v-icon>
                        Not enrolled in any courses yet
                      </td>
                    </tr>
                  </tbody>
                </v-table>
              </v-card-text>
            </v-card>
          </section>
          
          <!-- Recommended Section -->
          <section class="dashboard-section">
            <div class="section-header">
              <h2 class="section-title">
                <v-icon color="primary" class="section-icon">mdi-lightbulb</v-icon>
                Recommended for You
              </h2>
            </div>
            
            <v-card class="recommended-card" elevation="2">
              <v-card-text class="d-flex flex-column align-center justify-center py-10">
                <v-icon color="grey" size="64" class="mb-4">mdi-lightbulb-outline</v-icon>
                <p class="text-h6 text-grey">Coming Soon</p>
                <p class="text-subtitle-2 text-grey">We're working on personalized recommendations for you!</p>
              </v-card-text>
            </v-card>
          </section>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useUserStore } from '../store/store';
import instance from '../store/axios';
import router from '../router/router';

export default {
  setup() {
    const username = ref('');
    const totalCareerFits = ref('');
    const isLoading = ref(true);
    const totalSelectedJobFamilies = ref(0);
    const jobProspects = ref([]);
    const timeToFirstJob = ref(0);
    const salary = ref(0);
    const userStore = useUserStore();
    const regulated = ref('');
    
    const goToResults = () => {
      const cf_search = sessionStorage.getItem('cf_search');
      router.push({ name: 'Results', params: { cf_search } });
    };

    const checkUserData = () => new Promise(resolve => {
      const userdata = sessionStorage.getItem('userdata');
      const cf_search = JSON.parse(sessionStorage.getItem('cf_search'));
      if (userdata) {
        username.value = JSON.parse(userdata).firstName;
        totalCareerFits.value = ref(JSON.parse(sessionStorage.getItem('cf_saved')).length);
        totalSelectedJobFamilies.value = cf_search.selectedJobFamilies.length;
        jobProspects.value = cf_search.selectedJobProspects;
        timeToFirstJob.value = cf_search.timeToFirstJob;
        salary.value = cf_search.salary;
        regulated.value = cf_search.includeRegulated;
        resolve();
      }
    });

    const courses = computed(() => {
      return Object.entries(userStore.cf_coaching).map(([ , [ time, date, status, name]]) => ({
        course: name,
        status: status,
        date: date,
        time: time
      }));
    });

    const headers = [
      { title: 'Course Name', key: 'course' },
      { title: 'Status', key: 'status' },
      { title: 'Date', key: 'date' },
      { title: 'Time', key: 'time' }
    ];

    const formatCurrency = (value) => {
      return `$${parseInt(value).toLocaleString()}`;
    };
    
    const formatTimeToFirstJob = (months) => {
      if (months === 0) return 'ASAP';
      if (months === 12) return '1 Year';
      if (months === 24) return '2 Years';
      if (months === 36 || months === 48) return '3+ Years';
      return `${months} Months`;
    };
    
    const getStatusColor = (status) => {
      switch(status) {
        case 'Enrolled': return 'success';
        case 'Pending Enroll': return 'warning';
        default: return 'grey';
      }
    };

    const fetchEnrolled = async () => {
      if (userStore.cf_coaching && Object.keys(userStore.cf_coaching).length > 0) {
        return;
      }

      try {
        const response = await instance.get('coaching/enroll', {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        userStore.setEnrolledCoach(response.data.cf_coaching);
      } catch (error) {
        console.error(`Error fetching enrolled data:`, error);
      }
    };

    onMounted(async () => {
      await checkUserData();
      await fetchEnrolled();
      isLoading.value = false;
    });

    return {
      username,
      isLoading,
      totalCareerFits,
      totalSelectedJobFamilies,
      jobProspects,
      timeToFirstJob,
      goToResults,
      fetchEnrolled,
      userStore,
      headers,
      courses,
      salary,
      regulated,
      formatCurrency,
      formatTimeToFirstJob,
      getStatusColor
    };
  }
}
</script>

<style scoped>
/* Dashboard Layout */
.dashboard-container {
  padding-top: 1rem;
  padding-bottom: 3rem;
  max-width: 100%;
}

/* Welcome Card */
.welcome-card {
  border-radius: 16px;
  overflow: hidden;
  background: linear-gradient(to right, #f5f7fa 70%, #e1f0ff);
  position: relative;
}

.welcome-title {
  font-weight: 700;
  letter-spacing: -0.5px;
}

.welcome-subtitle {
  color: #455a64;
  max-width: 500px;
}

.dashboard-stats-col {
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
}

.dashboard-stats {
  text-align: center;
  padding: 2rem;
}

.stats-icon {
  opacity: 0.8;
}

.stats-number {
  font-size: 3rem;
  font-weight: 700;
  color: #1a237e;
  line-height: 1;
  margin-bottom: 0.5rem;
}

.stats-label {
  font-size: 1rem;
  color: #455a64;
  font-weight: 500;
}

/* Section Styles */
.dashboard-section {
  margin-bottom: 2.5rem;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}

.section-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1a237e;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.section-icon {
  margin-right: 0.75rem;
}

/* Search Section */
.search-card {
  border-radius: 12px;
  overflow: hidden;
}

.search-details {
  row-gap: 1.5rem;
}

.search-detail-item {
  display: flex;
  flex-direction: column;
}

.detail-label {
  font-size: 0.875rem;
  color: #78909c;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}

.detail-value {
  font-size: 1.125rem;
  font-weight: 600;
  color: #1a237e;
}

.detail-chip-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.detail-chip {
  font-weight: 500;
}

/* Coaching Section */
.coaching-card {
  border-radius: 12px;
  overflow: hidden;
}

.coaching-row:hover {
  background-color: #f8f9fa;
}

/* Recommended Section */
.recommended-card {
  border-radius: 12px;
  overflow: hidden;
  min-height: 200px;
}

/* Media queries */
@media (max-width: 960px) {
  .dashboard-stats-col {
    border-left: none;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }
}

@media (max-width: 600px) {
  .section-header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .section-actions {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    align-self: flex-end;
  }
  
  .welcome-content,
  .dashboard-stats {
    padding: 1.5rem !important;
  }
  
  .stats-number {
    font-size: 2.5rem;
  }
}
</style>