<template>
  <div class="fill-height">
    <!-- Loading spinner -->
    <v-container v-if="isLoading" class="d-flex flex-row align-center justify-center" style="height: 100vh;">
      <v-row justify="center" align="center">
        <v-col class="text-center">
          <v-progress-circular
            indeterminate
            size="70"
            width="7"
            color="primary">
          </v-progress-circular>
        </v-col>
      </v-row>
    </v-container>

    <!-- Job details content -->
    <div v-else class="job-details-page">
      <!-- Header section with back button only -->
      <div class="job-header">
        <v-container fluid>
          <v-row align="center">
            <v-col cols="auto">
              <v-btn icon color="primary" @click="goBack" variant="text" class="back-btn">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </v-col>
            <v-col>
              <h1 class="text-h5 font-weight-bold text-primary mb-0 text-truncate">{{ job.occupation }}</h1>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <!-- Main content -->
      <v-container fluid class="job-container">
        <v-row justify="center">
          <v-col cols="12" lg="10" xl="10">
            <v-row>
              <!-- Left column - Key information -->
              <v-col cols="12" md="4" order="2" order-md="1">
                <!-- Salary card -->
                <v-card class="mb-5 info-card" elevation="3">
                  <v-card-title class="pb-2">
                    <v-icon color="primary" class="mr-2">mdi-cash-multiple</v-icon>
                    Income Details
                  </v-card-title>
                  
                  <v-divider></v-divider>
                  
                  <v-list>
                    <!-- Salary -->
                    <v-list-item>
                      <template v-slot:prepend>
                        <div class="text-subtitle-2 font-weight-medium">
                          <v-icon color="success" size="small" class="mr-1">mdi-currency-usd</v-icon>
                          Salary
                        </div>
                      </template>
                      <template v-slot:append>
                        <span class="text-body-1 font-weight-medium" v-if="job.income_min && job.income_max">
                          ${{ (job.income_min / 1000).toFixed(0) }}k - ${{ (job.income_max / 1000).toFixed(0) }}k
                        </span>
                        <span class="text-medium-emphasis" v-else>Coming Soon</span>
                      </template>
                    </v-list-item>
                    
                    <!-- Hourly -->
                    <v-list-item>
                      <template v-slot:prepend>
                        <div class="text-subtitle-2 font-weight-medium">
                          <v-icon color="primary" size="small" class="mr-1">mdi-clock-outline</v-icon>
                          Hourly Rate
                        </div>
                      </template>
                      <template v-slot:append>
                        <span class="text-body-1 font-weight-medium" v-if="job.hourly_min && job.hourly_max">
                          ${{ job.hourly_min }}/hr - ${{ job.hourly_max }}/hr
                        </span>
                        <span class="text-medium-emphasis" v-else>Coming Soon</span>
                      </template>
                    </v-list-item>
                  </v-list>
                </v-card>
                
                <!-- Regulatory status card -->
                <v-card class="mb-5 info-card" elevation="3">
                  <v-card-title class="pb-2">
                    <v-icon color="primary" class="mr-2">mdi-certificate-outline</v-icon>
                    Regulatory Status
                  </v-card-title>
                  
                  <v-divider></v-divider>
                  
                  <div class="px-4 pt-4 pb-2">
                    <div class="reg-details mb-4">
                      <div class="text-subtitle-2 font-weight-medium mb-2">
                        <v-icon :color="getColor(job.regulated)" size="small" class="mr-1">{{ getIcon(job.regulated) }}</v-icon>
                        Regulation Status
                      </div>
                      <div class="ml-7">
                        <v-chip 
                          size="small" 
                          :color="getColor(job.regulated)" 
                          variant="flat"
                          v-if="job.regulated"
                        >
                          {{ job.regulated }}
                        </v-chip>
                        <span class="text-medium-emphasis" v-else>Coming Soon</span>
                      </div>
                    </div>
                  </div>
                  
                  <div v-if="job.regulated && job.regulated !== 'No'" class="px-4 pt-2 pb-4">
                    <div class="reg-details mb-4" v-if="job.program_name && job.program_name.trim().length">
                      <div class="text-subtitle-2 font-weight-medium mb-2">
                        <v-icon color="amber darken-3" size="small" class="mr-1">mdi-certificate</v-icon>
                        Program Name
                      </div>
                      <div class="ml-7">
                        <ul class="pl-3">
                          <li v-for="(item, index) in job.program_name.split(/[,;]/)" :key="index" class="mb-1">
                            {{ item.trim() }}
                          </li>
                        </ul>
                      </div>
                    </div>
                    
                    <div class="reg-details mb-4" v-if="job.school_location">
                      <div class="text-subtitle-2 font-weight-medium mb-2">
                        <v-icon color="primary" size="small" class="mr-1">mdi-town-hall</v-icon>
                        School Location
                      </div>
                      <div class="ml-7">{{ job.school_location }}</div>
                    </div>
                    
                    <div class="reg-details mb-4" v-if="job.average_cost">
                      <div class="text-subtitle-2 font-weight-medium mb-2">
                        <v-icon color="green" size="small" class="mr-1">mdi-cash</v-icon>
                        Average Cost
                      </div>
                      <div class="ml-7">${{ new Intl.NumberFormat().format(job.average_cost) }}</div>
                    </div>
                    
                    <div class="reg-details" v-if="job.minimum_study_time">
                      <div class="text-subtitle-2 font-weight-medium mb-2">
                        <v-icon color="primary" size="small" class="mr-1">mdi-book-open-variant</v-icon>
                        Study Time
                      </div>
                      <div class="ml-7">{{ job.minimum_study_time }} months</div>
                    </div>
                  </div>
                </v-card>
                
                <!-- Job info card -->
                <v-card class="mb-5 info-card" elevation="3">
                  <v-card-title class="pb-2">
                    <v-icon color="primary" class="mr-2">mdi-information-outline</v-icon>
                    Job Information
                  </v-card-title>
                  
                  <v-divider></v-divider>
                  
                  <div class="px-4 pt-2 pb-4">
                    <!-- Job Prospects -->
                    <div class="reg-details mb-4">
                      <div class="text-subtitle-2 font-weight-medium mb-2">
                        <v-icon color="primary" size="small" class="mr-1">mdi-briefcase-account</v-icon>
                        Prospects
                      </div>
                      <div class="ml-7">
                        <v-chip 
                          size="small" 
                          :color="getColor(job.job_prospects?.replace(' ', '_'))" 
                          variant="flat"
                          v-if="job.job_prospects"
                        >
                          {{ job.job_prospects }}
                        </v-chip>
                        <span class="text-medium-emphasis" v-else>Coming Soon</span>
                      </div>
                    </div>
                    
                    <!-- Competition -->
                    <div class="reg-details mb-4">
                      <div class="text-subtitle-2 font-weight-medium mb-2">
                        <v-icon color="amber" size="small" class="mr-1">mdi-trophy</v-icon>
                        Competition
                      </div>
                      <div class="ml-7">
                        <v-chip 
                          size="small" 
                          :color="getColor(job.competition?.replace(' ', '_'))" 
                          variant="flat"
                          v-if="job.competition"
                        >
                          {{ job.competition }}
                        </v-chip>
                        <span class="text-medium-emphasis" v-else>Coming Soon</span>
                      </div>
                    </div>
                    
                    <!-- Trend -->
                    <div class="reg-details mb-4">
                      <div class="text-subtitle-2 font-weight-medium mb-2">
                        <v-icon color="primary" size="small" class="mr-1">mdi-finance</v-icon>
                        Growth Trend
                      </div>
                      <div class="ml-7">
                        <v-chip 
                          size="small" 
                          :color="getColor(job.job_growth?.replace(' ', '_'))" 
                          variant="flat"
                          v-if="job.job_growth"
                        >
                          {{ job.job_growth }}
                        </v-chip>
                        <span class="text-medium-emphasis" v-else>Coming Soon</span>
                      </div>
                    </div>
                    
                    <!-- Commitment Type -->
                    <div class="reg-details mb-4">
                      <div class="text-subtitle-2 font-weight-medium mb-2">
                        <v-icon color="primary" size="small" class="mr-1">mdi-briefcase-clock</v-icon>
                        Commitment Type
                      </div>
                      <div class="ml-7">
                        <v-chip-group v-if="job.commitment">
                          <v-chip
                            v-for="(item, index) in job.commitment.split(/[,;]/)"
                            :key="index"
                            size="x-small"
                            class="mr-2"
                          >
                            {{ item.trim() }}
                          </v-chip>
                        </v-chip-group>
                        <span v-else class="text-medium-emphasis">Coming Soon</span>
                      </div>
                    </div>
                    
                    <!-- Work Mode -->
                    <div class="reg-details">
                      <div class="text-subtitle-2 font-weight-medium mb-2">
                        <v-icon color="primary" size="small" class="mr-1">mdi-map-marker</v-icon>
                        Work Mode
                      </div>
                      <div class="ml-7">
                        <v-chip-group v-if="job.virtual">
                          <v-chip
                            v-for="(item, index) in job.virtual.split(/[,;]/)"
                            :key="index"
                            size="x-small"
                            class="mr-2"
                          >
                            {{ item.trim() }}
                          </v-chip>
                        </v-chip-group>
                        <span v-else class="text-medium-emphasis">Coming Soon</span>
                      </div>
                    </div>
                  </div>
                </v-card>
              </v-col>
              
              <!-- Right column - Job details -->
              <v-col cols="12" md="8" order="1" order-md="2">
                <!-- Job description card -->
                <v-card class="mb-5 details-card" elevation="3">
                  <v-card-title class="pb-3">
                    <v-icon color="primary" class="mr-2">mdi-briefcase-variant</v-icon>
                    {{ job.occupation }} Overview
                  </v-card-title>
                  
                  <v-divider></v-divider>
                  
                  <!-- Job families section -->
                  <v-card-text class="pt-4">
                    <div class="d-flex align-center mb-4 job-families">
                      <div class="text-subtitle-1 font-weight-medium mr-3">Job Families:</div>
                      <v-chip
                        v-for="(item, index) in (job.Families || [])"
                        :key="index"
                        size="small"
                        class="mr-2"
                        color="primary"
                        variant="flat"
                      >
                        {{ item.replace(/_/g, ' ').toLowerCase().replace(/(?:^|\s)\S/g, a => a.toUpperCase()) }}
                      </v-chip>
                    </div>

                    <v-divider></v-divider>
                  
                    <div class="job-description text-body-1 pt-4 mb-6">
                      {{ job.description }}
                    </div>
                    
                    <div class="skill-section mb-5">
                      <h3 class="text-h6 font-weight-medium mb-3">
                        <v-icon color="primary" size="small" class="mr-2">mdi-check-circle</v-icon>
                        Required Skills
                      </h3>
                      <div class="skills-container pl-8">
                        <v-chip
                          v-for="(item, index) in (job.job_requirements_skills ? job.job_requirements_skills.split(/[,;]/) : [])"
                          :key="index"
                          class="mr-2 mb-2"
                          color="primary"
                          variant="outlined"
                        >
                          {{ item.trim() }}
                        </v-chip>
                        <p v-if="!job.job_requirements_skills" class="text-medium-emphasis">Coming Soon</p>
                      </div>
                    </div>
                    
                    <div class="skill-section mb-3">
                      <h3 class="text-h6 font-weight-medium mb-3">
                        <v-icon color="amber darken-2" size="small" class="mr-2">mdi-check-decagram</v-icon>
                        Preferred Skills
                      </h3>
                      <div class="skills-container pl-8">
                        <ul v-if="job.job_requirements_preferred" class="pl-4 preferred-skills">
                          <li v-for="(item, index) in job.job_requirements_preferred.split(/[,;]/)" :key="index" class="mb-2">
                            {{ item.trim() }}
                          </li>
                        </ul>
                        <p v-else class="text-medium-emphasis">Coming Soon</p>
                      </div>
                    </div>
                  </v-card-text>
                  
                  <v-divider class="mx-4"></v-divider>
                  
                  <v-card-actions class="pa-4">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      variant="tonal"
                      prepend-icon="mdi-post-outline"
                      @click="openJobPosting"
                      class="mr-3"
                    >
                      Example Job Posting
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      variant="outlined"
                      prepend-icon="mdi-bookmark"
                      @click="toggleSaveJob"
                    >
                      {{ isJobSaved ? 'Saved' : 'Save Job' }}
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
                
                <!-- Career requirements card -->
                <v-card class="mb-5 details-card" elevation="3">
                  <v-card-title class="pb-3">
                    <v-icon color="primary" class="mr-2">mdi-school</v-icon>
                    Career Requirements
                  </v-card-title>
                  
                  <v-divider></v-divider>
                  
                  <v-card-text>
                    <div class="education-section mb-5">
                      <h3 class="text-h6 font-weight-medium mb-3">
                        <v-icon color="primary" size="small" class="mr-2">mdi-account-school</v-icon>
                        Education Requirements
                      </h3>
                      <div class="education-chips pl-8">
                        <v-chip
                          v-for="(item, index) in (job.job_requirements_concrete ? job.job_requirements_concrete.split(/[,;]/) : [])"
                          :key="index"
                          class="mr-2 mb-2"
                          color="primary"
                          variant="outlined"
                        >
                          {{ item.trim() }}
                        </v-chip>
                        <p v-if="!job.job_requirements_concrete" class="text-medium-emphasis">Coming Soon</p>
                      </div>
                    </div>
                    
                    <div class="programs-section">
                      <h3 class="text-h6 font-weight-medium mb-3">
                        <v-icon color="primary" size="small" class="mr-2">mdi-school</v-icon>
                        Common Programs
                      </h3>
                      <div class="programs-chips pl-8">
                        <v-chip
                          v-for="(item, index) in (job.common_programs ? job.common_programs.split(/[,;]/) : [])"
                          :key="index"
                          class="mr-2 mb-2"
                          color="primary"
                          variant="outlined"
                        >
                          {{ item.trim() }}
                        </v-chip>
                        <p v-if="!job.common_programs" class="text-medium-emphasis">Coming Soon</p>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import instance from '../store/axios';
import { iconMap } from './UserResults.vue';

export default {
  props: ['jobId'],
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const isLoading = ref(true);
    const jobId = ref(props.jobId || route.params.jobId || 2302004);
    const job = ref({});
    const isJobSaved = ref(false);
    
    // Check if job is saved
    const checkIfSaved = () => {
      const savedJobs = sessionStorage.getItem('cf_saved') 
        ? JSON.parse(sessionStorage.getItem('cf_saved')) 
        : [];
      isJobSaved.value = savedJobs.includes(Number(jobId.value));
    };
    
    // Toggle save job
    const toggleSaveJob = async () => {
      const savedJobs = sessionStorage.getItem('cf_saved') 
        ? JSON.parse(sessionStorage.getItem('cf_saved')) 
        : [];
      
      if (isJobSaved.value) {
        // Remove job from saved list
        const index = savedJobs.indexOf(Number(jobId.value));
        if (index !== -1) {
          savedJobs.splice(index, 1);
          sessionStorage.setItem('cf_saved', JSON.stringify(savedJobs));
          isJobSaved.value = false;
          
          try {
            await instance.delete('profile/saved', {
              data: { jobId: jobId.value },
              headers: { 'Content-Type': 'application/json' },
            });
          } catch (error) {
            console.error('Failed to delete job:', error);
            // Restore saved status if API call fails
            savedJobs.push(Number(jobId.value));
            sessionStorage.setItem('cf_saved', JSON.stringify(savedJobs));
            isJobSaved.value = true;
          }
        }
      } else {
        // Add job to saved list
        savedJobs.push(Number(jobId.value));
        sessionStorage.setItem('cf_saved', JSON.stringify(savedJobs));
        isJobSaved.value = true;
        
        try {
          await instance.put('profile/saved', {
            data: { jobId: jobId.value },
            headers: { 'Content-Type': 'application/json' },
          });
        } catch (error) {
          console.error('Failed to save job:', error);
          // Remove from saved if API call fails
          const index = savedJobs.indexOf(Number(jobId.value));
          savedJobs.splice(index, 1);
          sessionStorage.setItem('cf_saved', JSON.stringify(savedJobs));
          isJobSaved.value = false;
        }
      }
    };

    const getColor = (category) => iconMap[category]?.color || 'grey';
    const getIcon = (category) => iconMap[category]?.icon || 'mdi-information';

    const openJobPosting = () => {
      const baseUrl = 'https://mvp.careerfit.ca/postings/';
      const pdfUrl = `${baseUrl}${jobId.value}.pdf`;
      window.open(pdfUrl, '_blank');
    };

    const goBack = () => {
      router.go(-1);
    };

    onMounted(async () => {
      // Make sure we have the job ID from route params
      if (route.params.jobId) {
        jobId.value = route.params.jobId;
      }
      
      try {
        isLoading.value = true;
        
        const response = await instance.get(`search/results/${jobId.value}`, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        // Handle a single job object
        if (response.data && response.data.length > 0) {
          const jobData = response.data[0];
          job.value = { ...jobData };
        }
        
        // Check if this job is saved
        checkIfSaved();
      } 
      catch (error) {
        console.error('Failed to fetch job:', error);
      } 
      finally {
        isLoading.value = false;
      }
    });

    return { 
      goBack,
      isLoading,
      job,
      getColor,
      getIcon,
      openJobPosting,
      isJobSaved,
      toggleSaveJob
    };
  },
};
</script>

<style scoped>
/* Page layout */
.job-details-page {
  min-height: 100vh;
  background-color: #f8f9fa;
  padding-bottom: 4rem;
}

.job-header {
  background-color: white;
  padding: 1rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
}

.job-container {
  max-width: 1600px;
  padding-top: 1rem;
}

.back-btn {
  margin-right: 1rem;
}

.job-families {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

/* Card styles */
.info-card, .details-card {
  border-radius: 12px;
  overflow: hidden;
}

.details-card .v-card-title {
  font-size: 1.25rem;
  font-weight: 600;
}

/* Job description styles */
.job-description {
  line-height: 1.6;
  color: #333;
}

/* Skills section */
.skills-container {
  display: flex;
  flex-wrap: wrap;
}

.preferred-skills {
  line-height: 1.6;
  color: #333;
}

.preferred-skills li {
  margin-bottom: 0.5rem;
}

/* Regulatory details */
.reg-details {
  margin-left: 1rem;
}

/* Media queries */
@media (max-width: 959px) {
  .info-card {
    margin-bottom: 1.5rem;
  }
}

@media (max-width: 599px) {
  .job-container {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  
  .job-description {
    font-size: 0.95rem;
  }
}
</style>
