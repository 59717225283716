<template>
<v-app>
  <v-main style="background: linear-gradient(to bottom right, #edf8ff, #e1f0ff);">
    <!-- Header Section -->
    <div class="header-section py-16">
      <v-container>
        <v-row justify="center">
          <v-col cols="12" sm="10" md="8" class="text-center">
            <h1 class="page-title mb-6">Meet Our Team</h1>
            <p class="page-subtitle">We are Internationally Educated Health Professionals (IEHPs) too!</p>
            <div class="header-divider mx-auto mt-6 mb-8"></div>
            <v-btn 
              color="#4b9d77" 
              size="large"
              rounded
              elevation="2"
              href="https://www.rimax.ca" 
              target="_blank" 
              class="visit-btn"
              prepend-icon="mdi-open-in-new"
            >
              Visit Rimax.ca
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- Team Members Section -->
    <v-container class="team-container py-10">
      <!-- Team Member 1 -->
      <div class="team-card">
        <v-row align="center">
          <v-col cols="12" md="4" lg="3" class="team-img-col">
            <div class="team-img-wrapper">
              <v-img
                src="../assets/pictures/Mihai.jpg"
                alt="Mihai Csaki"
                class="team-img"
                cover
              ></v-img>
              <div class="social-link">
                <v-btn 
                  icon
                  variant="text"
                  href="https://www.linkedin.com/in/mihaicsaki/" 
                  target="_blank"
                  color="white"
                >
                  <v-icon size="36">mdi-linkedin</v-icon>
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="8" lg="9" class="team-content">
            <div class="name-tag">Founder & Lead Mentor</div>
            <h2 class="member-name">Mihai Csaki</h2>
            <div class="member-specialty">Career Coach & Specialist | Alternative career paths | IMGs | Clinical Research | Pharmaceutical Space | Digital health | Medical Affairs</div>
            <p class="member-bio">
              International Medical Graduate (EU license restricted), held executive positions in Marketing, Sales, Clinical research, Headhunting and Occupational Health services, Scientific Affairs. Experienced in Career coaching, Adult Learning, Pharmaceuticals, Medical Devices, Pharmacy, Entrepreneurship, with a deep interest in Digital Health.
            </p>
          </v-col>
        </v-row>
      </div>
      
      <!-- Team Member 2 -->
      <div class="team-card">
        <v-row align="center">
          <v-col cols="12" md="4" lg="3" order-md="last" class="team-img-col">
            <div class="team-img-wrapper">
              <v-img
                src="../assets/pictures/Abbas.jpg"
                alt="Abbas Zavar"
                class="team-img"
                cover
              ></v-img>
              <div class="social-link">
                <v-btn 
                  icon
                  variant="text"
                  href="https://www.linkedin.com/in/abbaszavar/" 
                  target="_blank"
                  color="white"
                >
                  <v-icon size="36">mdi-linkedin</v-icon>
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="8" lg="9" class="team-content">
            <div class="name-tag">Digital Health Specialist</div>
            <h2 class="member-name">Abbas Zavar</h2>
            <div class="member-specialty">Career Coach & Specialist | Health Informatics Coach | IMGs | Public Health | Digital health</div>
            <p class="member-bio">
              An international medical doctor who also held Master of Public Health and Master of Health Informatics, with solid expertise in adult education, coaching and mentoring. Outstanding knowledge and experience in the Canadian digital health domain: clinical decision support system, clinical business intelligence and clinical data sciences.
            </p>
          </v-col>
        </v-row>
      </div>
      
      <!-- Team Member 3 -->
      <div class="team-card">
        <v-row align="center">
          <v-col cols="12" md="4" lg="3" class="team-img-col">
            <div class="team-img-wrapper">
              <v-img
                src="../assets/pictures/Cindy.jpeg"
                alt="Cindy"
                class="team-img"
                cover
              ></v-img>
              <div class="social-link">
                <v-btn 
                  icon
                  variant="text"
                  href="https://www.linkedin.com/in/cindy-sinclair-11a92520/" 
                  target="_blank"
                  color="white"
                >
                  <v-icon size="36">mdi-linkedin</v-icon>
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="8" lg="9" class="team-content">
            <div class="name-tag">Career Coach, Educator and Researcher</div>
            <h2 class="member-name">Cindy</h2>
            <div class="member-specialty">Alternative professional and academic careers paths | Immigrant Medical Doctors | IEHPs | Workplace Integration | Social Science Research</div>
            <p class="member-bio">
              U of Toronto affiliated PhD educator, researcher and former staff with 10+ years experience in coaching, guiding and mentoring students and newcomer immigrants in achieving their desired job and education goals. A warm and empathic mentor with proven track record on achieving higher goals.
            </p>
          </v-col>
        </v-row>
      </div>
      
      <!-- Team Member 4 -->
      <div class="team-card">
        <v-row align="center">
          <v-col cols="12" md="4" lg="3" order-md="last" class="team-img-col">
            <div class="team-img-wrapper">
              <v-img
                src="../assets/pictures/Pauline.jpeg"
                alt="Pauline"
                class="team-img"
                cover
              ></v-img>
              <div class="social-link">
                <v-btn 
                  icon
                  variant="text"
                  href="https://www.linkedin.com/in/abbaszavar/" 
                  target="_blank"
                  color="white"
                >
                  <v-icon size="36">mdi-linkedin</v-icon>
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="8" lg="9" class="team-content">
            <div class="name-tag">Career Coach & Specialist</div>
            <h2 class="member-name">Pauline</h2>
            <div class="member-specialty">Career Coach & Specialist | Alternative career paths | IMDs & IEHPs | Clinical Research | Medical Administration | Hospital and Healthcare</div>
            <p class="member-bio">
              International Medical Doctor (IMD) currently working in the field of clinical research at a leading academic institute in Toronto. Extensive knowledge in Canadian healthcare structure, context & culture, clinical research, and medical administration. Years of experience in career guidance and mentoring of internationally educated healthcare professionals (IEHPs).
            </p>
          </v-col>
        </v-row>
      </div>
      
      <!-- Team Member 5 -->
      <div class="team-card">
        <v-row align="center">
          <v-col cols="12" md="4" lg="3" class="team-img-col">
            <div class="team-img-wrapper">
              <v-img
                src="../assets/pictures/Ioana.jpg"
                alt="Ioana Modroiu"
                class="team-img"
                cover
              ></v-img>
              <div class="social-link">
                <v-btn 
                  icon
                  variant="text"
                  href="https://www.linkedin.com/in/ioanamodroiu/" 
                  target="_blank"
                  color="white"
                >
                  <v-icon size="36">mdi-linkedin</v-icon>
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="8" lg="9" class="team-content">
            <div class="name-tag">Peer and Guide to IEHPs</div>
            <h2 class="member-name">Ioana Modroiu</h2>
            <div class="member-specialty">Career Coach & Specialist | Alternative career paths | IMGs | Clinical Research | Hospital & Healthcare</div>
            <p class="member-bio">
              An International Medical Doctor currently working as a Senior Training Manager for a Pharmaceutical company in GTA.
            </p>
          </v-col>
        </v-row>
      </div>
      
      <!-- Team Member 6 -->
      <div class="team-card">
        <v-row align="center">
          <v-col cols="12" md="4" lg="3" order-md="last" class="team-img-col">
            <div class="team-img-wrapper">
              <v-img
                src="../assets/pictures/Swetha.png"
                alt="Swetha"
                class="team-img"
                cover
              ></v-img>
              <div class="social-link">
                <v-btn 
                  icon
                  variant="text"
                  href="https://www.linkedin.com/in/swetha-rc/" 
                  target="_blank"
                  color="white"
                >
                  <v-icon size="36">mdi-linkedin</v-icon>
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="8" lg="9" class="team-content">
            <div class="name-tag">Peer and Guide to IEHPs</div>
            <h2 class="member-name">Swetha</h2>
            <div class="member-specialty">Career Coach | Alternative career paths | IMGs | Digital health</div>
            <p class="member-bio">
              An Internationally Educated Family Physician with recent work experience as a career coach and mentor. Interested in exchanging knowledge on career search options in Canadian healthcare.
            </p>
          </v-col>
        </v-row>
      </div>
    </v-container>
    
    <!-- Join Us CTA Section -->
    <div class="cta-section py-16">
      <v-container>
        <v-row justify="center">
          <v-col cols="12" sm="10" md="8" lg="6" class="text-center">
            <h2 class="cta-title mb-6">Join Our Team of Healthcare Professionals</h2>
            <p class="cta-text mb-8">Be part of our community that helps international healthcare professionals navigate the Canadian healthcare system</p>
            <v-btn 
              color="primary" 
              size="large" 
              rounded 
              elevation="2"
              class="cta-button"
              @click="goToFormWizard"
            >
              <v-icon start>mdi-rocket-launch</v-icon>
              Get Started Today
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-main>
</v-app>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'AboutUs',
  setup() {
    const router = useRouter();
    const activeTab = ref(null);
    const resetActiveTab = () => {
      activeTab.value = null;
    };
    const goToLogin = () => {
      router.push({ name: 'UserLogin' });
    };
    const goToFormWizard = () => {
      router.push({ name: 'UserFormWizard' });
    };

    return {
      activeTab,
      goToLogin,
      goToFormWizard,
      resetActiveTab
    };
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler(to) {
        if (to === '/') {
          this.resetActiveTab();
        }
      },
    },
  },
};
</script>

<style scoped>
/* Header Section */
.header-section {
  background: linear-gradient(135deg, #1a237e 0%, #42a5f5 100%);
  color: white;
  position: relative;
  padding: 4rem 0;
}

.page-title {
  font-size: clamp(2.5rem, 5vw, 3.5rem);
  font-weight: 700;
  letter-spacing: -0.5px;
}

.page-subtitle {
  font-size: clamp(1.2rem, 2vw, 1.5rem);
  font-weight: 300;
  max-width: 600px;
  margin: 0 auto;
  opacity: 0.9;
}

.header-divider {
  height: 4px;
  width: 80px;
  background: white;
  border-radius: 2px;
}

.visit-btn {
  font-weight: 500;
  letter-spacing: 0.5px;
  padding: 0.75rem 2rem;
  transition: all 0.3s ease;
}

.visit-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

/* Team Container */
.team-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1.5rem;
}

/* Team Card */
.team-card {
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.08);
  margin-bottom: 3rem;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.team-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

/* Team Image */
.team-img-col {
  padding: 0;
}

.team-img-wrapper {
  position: relative;
  height: 100%;
  min-height: 300px;
  overflow: hidden;
}

.team-img {
  height: 100%;
  min-height: 300px;
  transition: transform 0.5s ease;
}

.team-img-wrapper:hover .team-img {
  transform: scale(1.05);
}

.social-link {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  background: rgba(26, 35, 126, 0.8);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;
}

.social-link:hover {
  background: rgba(26, 35, 126, 1);
}

/* Team Content */
.team-content {
  padding: 2.5rem;
}

.name-tag {
  display: inline-block;
  background-color: #e3f2fd;
  color: #1a237e;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0.25rem 0.75rem;
  border-radius: 30px;
  margin-bottom: 1rem;
}

.member-name {
  font-size: 1.75rem;
  font-weight: 700;
  color: #1a237e;
  margin-bottom: 0.75rem;
}

.member-specialty {
  font-size: 0.95rem;
  line-height: 1.6;
  color: #455a64;
  font-weight: 500;
  margin-bottom: 1.25rem;
  border-left: 3px solid #42a5f5;
  padding-left: 1rem;
}

.member-bio {
  font-size: 1rem;
  line-height: 1.7;
  color: #455a64;
}

/* CTA Section */
.cta-section {
  background: linear-gradient(135deg, #1a237e 0%, #42a5f5 100%);
  color: white;
  position: relative;
  padding: 4rem 0;
}

.cta-title {
  font-size: clamp(2rem, 4vw, 2.8rem);
  font-weight: 700;
}

.cta-text {
  font-size: 1.2rem;
  font-weight: 300;
  max-width: 600px;
  margin: 0 auto;
  opacity: 0.9;
}

.cta-button {
  padding: 0.75rem 2rem;
  font-weight: 600;
  font-size: 1.1rem;
  background: white !important;
  color: #1a237e !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cta-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2) !important;
}

@media (max-width: 959px) {
  .team-content {
    padding: 2rem 1.5rem;
  }
  
  .team-img-wrapper {
    min-height: 350px;
  }
  
  .social-link {
    bottom: 0.5rem;
    right: 0.5rem;
  }
}

@media (max-width: 599px) {
  .team-content {
    padding: 1.5rem;
  }
  
  .team-img-wrapper {
    min-height: 280px;
  }
  
  .member-name {
    font-size: 1.5rem;
  }
}
</style>