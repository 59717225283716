<template>
  <!-- Loading spinner -->
  <v-container v-if="isLoading" class="d-flex flex-row align-center justify-center" style="height: 100vh;">
    <v-row justify="center" align="center">
      <v-col class="text-center">
        <v-progress-circular indeterminate size="70" width="7" color="primary"></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>

  <!-- Main content -->
  <div v-else class="saved-page">
    <!-- Header section with back button and title -->
    <div class="saved-header">
      <v-container fluid>
        <v-row align="center">
          <v-col cols="auto">
            <v-btn icon color="primary" @click="clearAndGoBack" variant="text" class="back-btn">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <h1 class="text-h5 font-weight-bold text-primary mb-0">Saved Careers</h1>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- Main Layout -->
    <v-container fluid class="saved-container">
      <v-row>
        <!-- No saved jobs message -->
        <v-col v-if="jobs.length === 0" cols="12">
          <v-card class="text-center pa-6 empty-state" elevation="3">
            <v-icon size="64" color="grey" class="mb-4">mdi-bookmark-outline</v-icon>
            <h3 class="text-h5 font-weight-medium mb-2">No saved careers found</h3>
            <p class="text-body-1 mb-4 text-medium-emphasis">Save careers from your search results to see them here</p>
            <v-btn color="primary" to="/search" prepend-icon="mdi-magnify">Start New Search</v-btn>
          </v-card>
        </v-col>

        <!-- Saved jobs grid -->
        <template v-else>
          <v-col
            v-for="job in jobs"
            :key="job.ID"
            cols="12"
            sm="6"
            md="4"
            lg="3"
            class="job-card-col"
          >
            <v-card class="job-card" elevation="3" @click="viewJobDetails(job.ID)">
              <!-- Save button -->
              <v-btn
                icon
                variant="text"
                density="comfortable"
                class="save-btn"
                @click.stop="toggleHeart(job.ID)"
              >
                <v-icon color="red" size="24">mdi-heart</v-icon>
              </v-btn>

              <!-- Card header -->
              <div class="job-card-header">
                <h3 class="job-title">{{ job.Occupation }}</h3>
                <div class="job-salary">
                  <v-icon color="success" class="mr-1" size="small">mdi-currency-usd</v-icon>
                  {{ formatSalaryRange(job.Income_Min, job.Income_Max) }}
                </div>
              </div>

              <!-- Card content -->
              <v-divider class="my-2"></v-divider>
              
              <!-- Job attributes -->
              <div class="job-attributes">
                <div class="attribute">
                  <span class="attribute-label">Regulated:</span>
                  <v-chip
                    size="x-small"
                    :color="getColor(job.Regulated)"
                    class="ms-2"
                    variant="flat"
                  >
                    {{ job.Regulated }}
                  </v-chip>
                </div>
                
                <div class="attribute">
                  <span class="attribute-label">Type:</span>
                  <span class="attribute-value">{{ job.Commitment }}</span>
                </div>

                <div class="attribute">
                  <span class="attribute-label">Prospects:</span>
                  <v-chip
                    size="x-small"
                    :color="getColor(job.Prospects.replace(' ', '_'))"
                    class="ms-2"
                    variant="flat"
                  >
                    {{ job.Prospects }}
                  </v-chip>
                </div>

                <div class="attribute">
                  <span class="attribute-label">Work Mode:</span>
                  <span class="attribute-value">{{ job.Virtual ? job.Virtual.split(',')[0] : 'N/A' }}</span>
                </div>
              </div>

              <!-- View details button -->
              <v-card-actions class="job-card-actions">
                <v-btn
                  color="primary"
                  variant="tonal"
                  block
                  :to="{ name: 'JobDetails', params: { jobId: job.ID } }"
                  prepend-icon="mdi-information-outline"
                >
                  View Details
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </div>

  <!-- Scroll to top button -->
  <v-btn
    v-show="showScrollTop"
    color="primary"
    icon
    fixed
    bottom
    right
    class="scroll-to-top-btn"
    @click="scrollToTop"
  >
    <v-icon>mdi-arrow-up</v-icon>
  </v-btn>
</template>

<script>
import { ref, onMounted, nextTick, onUnmounted } from 'vue';
import { useUserStore } from '../store/store';
import router from '../router/router';
import instance from '../store/axios';
import { iconMap } from './UserResults.vue';

export default {
  setup() {
    const jobs = ref([]);
    const isLoading = ref(true);
    const userStore = useUserStore();
    const showScrollTop = ref(false);
    const cf_saved = ref(
      sessionStorage.getItem('cf_saved') ? JSON.parse(sessionStorage.getItem('cf_saved')) : []
    );

    // Get color for various statuses
    const getColor = (category) => iconMap[category]?.color || 'grey';
    
    // Get icon for various statuses
    const getIcon = (category) => iconMap[category]?.icon || 'mdi-information';

    // Format salary range for display
    const formatSalaryRange = (min, max) => {
      return `$${Math.round(min/1000)}k - $${Math.round(max/1000)}k`;
    };

    // Check if a job is saved
    const isJobSaved = (jobId) => cf_saved.value.includes(jobId);

    // Get heart icon based on saved status
    const heartIcon = (jobId) => (isJobSaved(jobId) ? 'mdi-heart' : 'mdi-heart-outline');

    // Navigate to job details
    const viewJobDetails = (jobId) => {
      router.push({ name: 'JobDetails', params: { jobId } });
    };

    // Toggle heart (save/unsave job)
    const toggleHeart = async (jobId) => {
      if (isJobSaved(jobId)) {
        const index = cf_saved.value.indexOf(jobId);
        cf_saved.value.splice(index, 1);
        await nextTick();
        try {
          await instance.delete('profile/saved', {
            data: { jobId },
            headers: { 'Content-Type': 'application/json' },
          });
          // Remove job from the visible list
          jobs.value = jobs.value.filter(job => job.ID !== jobId);
        } catch (error) {
          console.error('Failed to delete job:', error);
          cf_saved.value.push(jobId);
        }
      } else {
        cf_saved.value.push(jobId);
        await nextTick();
        try {
          await instance.put('profile/saved', {
            data: { jobId },
            headers: { 'Content-Type': 'application/json' },
          });
        } catch (error) {
          console.error('Failed to save job:', error);
          const index = cf_saved.value.indexOf(jobId);
          cf_saved.value.splice(index, 1);
        }
      }
      sessionStorage.setItem('cf_saved', JSON.stringify(cf_saved.value));
    };

    // Go back
    const clearAndGoBack = () => {
      userStore.clearResults(); // Clear searchResults
      router.go(-1); // Go back
    };

    // Scroll to top
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // Show/hide scroll to top button
    const handleScroll = () => {
      showScrollTop.value = window.scrollY > 300;
    };

    onMounted(async () => {
      window.addEventListener('scroll', handleScroll);
      
      try {
        isLoading.value = true;
        
        // Get saved jobs from store or API
        const savedJobIds = sessionStorage.getItem('cf_saved') 
          ? JSON.parse(sessionStorage.getItem('cf_saved')) 
          : [];
        
        if (userStore.cf_saved_jobs && userStore.cf_saved_jobs.length === savedJobIds.length) {
          jobs.value = userStore.cf_saved_jobs;
        } else {
          if (savedJobIds.length > 0) {
            const response = await instance.get('profile/saved', {
              params: { 'cf_saved': savedJobIds },
              headers: { 'Content-Type': 'application/json' }
            });
            
            jobs.value = response.data.map(job => ({
              Occupation: job.occupation,
              Description: job.description,
              Prospects: job.job_prospects,
              Regulated: job.regulated,
              Income_Min: job.income_min,
              Income_Max: job.income_max,
              ID: job.id,
              Trend: job.job_growth,
              Virtual: job.virtual,
              Count: job.Families,
              Commitment: job.commitment,
            }));

            userStore.setCfSavedJobs(jobs.value);
          }
        }
      } catch (error) {
        console.error('Failed to fetch saved jobs:', error);
      } finally {
        isLoading.value = false;
      }
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    return { 
      jobs,
      heartIcon,
      isLoading,
      isJobSaved,
      toggleHeart,
      clearAndGoBack,
      getColor,
      getIcon,
      formatSalaryRange,
      viewJobDetails,
      showScrollTop,
      scrollToTop
    };
  }
}
</script>

<style scoped>
/* Page layout */
.saved-page {
  min-height: 100vh;
  background-color: #f8f9fa;
  padding-bottom: 4rem;
}

.saved-header {
  background-color: white;
  padding: 1rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
}

.back-btn {
  margin-right: 1rem;
}

.saved-container {
  max-width: 1600px;
}

/* Job cards */
.job-cards-container {
  row-gap: 1.5rem;
}

.job-card-col {
  display: flex;
}

.job-card {
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  position: relative;
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.job-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1) !important;
}

.save-btn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 1;
}

.job-card-header {
  padding: 1.5rem 1.5rem 0.5rem;
}

.job-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #344767;
  margin-bottom: 0.5rem;
  padding-right: 2rem;
  line-height: 1.4;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 3em;
}

.job-salary {
  color: #4caf50;
  font-weight: 600;
  font-size: 0.95rem;
  display: flex;
  align-items: center;
}

.job-attributes {
  padding: 0.75rem 1.5rem 1.25rem;
  flex-grow: 1;
}

.attribute {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}

.attribute-label {
  font-weight: 500;
  color: #64748b;
  font-size: 0.875rem;
}

.attribute-value {
  margin-left: 0.5rem;
  font-size: 0.875rem;
  color: #344767;
}

.job-card-actions {
  padding: 0.75rem 1rem 1rem;
}

/* Empty state */
.empty-state {
  border-radius: 12px;
}

/* Scroll to top button */
.scroll-to-top-btn {
  margin: 1rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

/* Responsive adjustments */
@media (max-width: 960px) {
  .job-title {
    font-size: 1rem;
  }
}
</style>