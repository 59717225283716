<template>
  <!-- Loading spinner -->
  <v-container v-if="isLoading" class="d-flex flex-row align-center justify-center" style="height: 100vh;">
    <v-row justify="center" align="center">
      <v-col class="text-center">
        <v-progress-circular
          indeterminate
          size="70"
          width="7"
          color="primary">
        </v-progress-circular>
      </v-col>
    </v-row>
  </v-container>

  <!-- Main content -->
  <div v-else class="coaching-page">
    <!-- Header section with back button and title -->
    <div class="coaching-header">
      <v-container fluid>
        <v-row align="center">
          <v-col cols="auto">
            <v-btn icon color="primary" @click="goBack" variant="text" class="back-btn">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <h1 class="text-h5 font-weight-bold text-primary mb-0">CareerFit Coaching Sessions</h1>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- Main content area -->
    <v-container fluid class="coaching-container">
      <v-row justify="center" style="row-gap: 24px; column-gap: 16px;">
        <v-col
          v-for="(item, index) in sortedCoachingContent"
          :key="index"
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <v-card class="pa-0 card-hover" elevation="3" height="100%">
            <div class="card-image-container">
              <v-img
                v-if="item.path"
                :src="`https://mvp.careerfit.ca/coaching/splashpics/${item.path}.png`"
                height="160"
                cover
                class="rounded-t"
              >
                <template v-slot:placeholder>
                  <div class="d-flex align-center justify-center fill-height">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                </template>
              </v-img>
              <div v-else class="no-image-container rounded-t d-flex flex-column align-center justify-center">
                <v-icon size="48" color="grey">mdi-lightbulb-outline</v-icon>
                <span class="text-subtitle-2 text-grey mt-2">Coming Soon</span>
              </div>
            </div>
            
            <v-card-text>
              <h3 class="text-subtitle-1 font-weight-bold card-title">{{ item.name }}</h3>
              <v-divider class="mb-2"></v-divider>
              
              <div class="mb-3">
                <div class="d-flex align-center mb-2">
                  <v-icon color="primary" size="small" class="mr-2">mdi-clock-time-four-outline</v-icon>
                  <span class="text-body-2">{{ item.time }} minutes</span>
                </div>
                
                <div class="d-flex align-center">
                  <v-icon color="primary" size="small" class="mr-2">mdi-account-group</v-icon>
                  <span class="text-body-2">Delivery:</span>
                  <v-chip
                    size="x-small"
                    :color="getCategoryColor(item.category)"
                    text-color="white"
                    class="ml-2"
                    label
                  >
                    {{ item.category }}
                  </v-chip>
                </div>
              </div>
              
              <p class="text-truncate-2 text-body-2 text-medium-emphasis">{{ item.description }}</p>
            </v-card-text>
            
            <v-card-actions class="px-4 pb-4 pt-0">
              <v-btn
                color="primary"
                variant="outlined"
                block
                :to="`/coaching/${item.id}`"
                class="text-none"
              >
                Learn More
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import axios from '../store/axios';

export default {
  setup() {
    const router = useRouter();
    const isLoading = ref(true);
    const coachingContent = ref([]);

    // Sort coaching content - items with path come first
    const sortedCoachingContent = computed(() => {
      return [...coachingContent.value].sort((a, b) => {
        if (a.path && !b.path) return -1;
        if (!a.path && b.path) return 1;
        return 0;
      });
    });

    const getCategoryColor = (category) => {
      switch(category) {
        case 'Group':
          return 'purple';
        case 'Individual':
          return 'blue';
        case 'Both':
          return 'teal';
        default:
          return 'grey';
      }
    };

    const goBack = () => {
      router.go(-1);
    };

    const fetchCoachingContent = async () => {
      try {
        console.log('Fetching all coaching content');
        const response = await axios.get('/coaching');
        console.log('Coaching data received:', response.data);
        if (response.data && response.data.length > 0) {
          console.log('Sample item properties:', Object.keys(response.data[0]));
          console.log('Path value available:', response.data.some(item => item.path));
        }
        coachingContent.value = response.data;
      } catch (error) {
        console.error('Error fetching coaching content:', error);
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchCoachingContent();
    });

    return {
      isLoading,
      coachingContent,
      sortedCoachingContent,
      getCategoryColor,
      goBack
    };
  },
};
</script>

<style scoped>
/* Page layout */
.coaching-page {
  min-height: calc(100vh - 64px); /* Adjust this based on your header size */
  background-color: #f8f9fa;
  padding-bottom: 4rem;
}

.coaching-header {
  background-color: white;
  padding: 1rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
}

.back-btn {
  margin-right: 1rem;
}

.coaching-container {
  max-width: 1600px;
  padding-top: 1rem;
}

/* Added styles for no-image container */
.no-image-container {
  height: 160px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Existing styles */
.text-truncate-2 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-title {
  line-height: 1.4;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 44px;
}

.card-hover {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-hover:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0,0,0,0.1) !important;
}

.mb-1 {
  margin-bottom: 2px;
}

.mr-2 {
  margin-right: 8px;
}

.mb-3 {
  margin-bottom: 12px;
}

.mb-6 {
  margin-bottom: 24px;
}

.card-image-container {
  position: relative;
}

.card-overlay {
  position: absolute;
  bottom: 12px;
  right: 12px;
}

.category-chip {
  font-weight: 600 !important;
  font-size: 11px !important;
}

.rounded-t {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
</style>