<template>
  <!-- Loading spinner -->
  <v-container v-if="isLoading" class="d-flex flex-row align-center justify-center" style="height: 100vh;">
    <v-row justify="center" align="center">
      <v-col class="text-center">
        <v-progress-circular indeterminate size="70" width="7" color="primary"></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>

  <!-- Main content -->
  <div v-else class="results-page">
    <!-- Header section with back button and title -->
    <div class="results-header">
      <v-container fluid>
        <v-row align="center">
          <v-col cols="auto">
            <v-btn icon color="primary" @click="clearAndGoBack" variant="text" class="back-btn">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <h1 class="text-h5 font-weight-bold text-primary mb-0">Career Results</h1>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- Main Layout -->
    <v-container fluid class="results-container">
      <v-row>
        <!-- Filters Section -->
        <v-col cols="12" sm="5" md="5" lg="4" xl="3">
          <v-card class="filters-card" elevation="3">
            <v-card-title class="d-flex align-center justify-space-between py-4">
              <div class="text-h6">Filters</div>
              <div class="d-flex align-center">
                <v-btn
                  color="primary"
                  variant="text"
                  density="comfortable"
                  @click="clearFilters"
                  prepend-icon="mdi-refresh"
                  size="small"
                  class="me-2"
                >
                  Clear Filters
                </v-btn>
                <v-chip color="primary" variant="elevated" class="results-count">
                  {{ filteredJobs.length }} results
                </v-chip>
              </div>
            </v-card-title>
            
            <v-divider></v-divider>
            
            <v-card-text class="filters-content">
              
              <!-- Job Families -->
              <div class="filter-section">
                <h3 class="filter-title">
                  <v-icon color="primary" size="small" class="mr-2">mdi-briefcase-variant</v-icon>
                  Job Families
                </h3>
                <div class="filter-chips">
                  <v-chip-group v-model="selectedJobFamiliesModel" key="families" column multiple>
                    <v-chip
                      v-for="family in jobFamilies"
                      :key="family"
                      :value="family"
                      class="ma-1"
                      filter
                      color="primary"
                      variant="outlined"
                    >
                      {{ formatFamilyName(family) }}
                    </v-chip>
                  </v-chip-group>
                </div>
              </div>
              
              <!-- Regulated -->
              <div class="filter-section">
                <h3 class="filter-title">
                  <v-icon color="primary" size="small" class="mr-2">mdi-certificate-outline</v-icon>
                  Regulated
                </h3>
                <div class="filter-chips">
                  <v-chip-group v-model="selectedRegulatedModel" key="regulated" multiple>
                    <v-chip
                      v-for="option in filterRegulated"
                      :key="option"
                      :value="option"
                      class="ma-1"
                      filter
                      color="primary"
                      variant="outlined"
                    >
                      {{ option }}
                    </v-chip>
                  </v-chip-group>
                </div>
              </div>
              
              <!-- Prospects -->
              <div class="filter-section">
                <h3 class="filter-title">
                  <v-icon color="primary" size="small" class="mr-2">mdi-chart-line</v-icon>
                  Prospects
                </h3>
                <div class="filter-chips">
                  <v-chip-group v-model="selectedCompetitionModel" key="prospects" multiple>
                    <v-chip
                      v-for="option in filterCompetition"
                      :key="option"
                      :value="option"
                      class="ma-1"
                      filter
                      color="primary"
                      variant="outlined"
                    >
                      {{ option }}
                    </v-chip>
                  </v-chip-group>
                </div>
              </div>
              
              <!-- Growth -->
              <div class="filter-section">
                <h3 class="filter-title">
                  <v-icon color="primary" size="small" class="mr-2">mdi-trending-up</v-icon>
                  Growth
                </h3>
                <div class="filter-chips">
                  <v-chip-group v-model="selectedJobGrowthModel" key="growth" multiple>
                    <v-chip
                      v-for="option in filterJobGrowth"
                      :key="option"
                      :value="option"
                      class="ma-1"
                      filter
                      color="primary"
                      variant="outlined"
                    >
                      {{ option }}
                    </v-chip>
                  </v-chip-group>
                </div>
              </div>
              
              <!-- Virtual -->
              <div class="filter-section">
                <h3 class="filter-title">
                  <v-icon color="primary" size="small" class="mr-2">mdi-laptop</v-icon>
                  Work Mode
                </h3>
                <div class="filter-chips">
                  <v-chip-group v-model="selectedVirtualModel" key="virtual" multiple>
                    <v-chip
                      v-for="option in filterVirtual"
                      :key="option"
                      :value="option"
                      class="ma-1"
                      filter
                      color="primary"
                      variant="outlined"
                    >
                      {{ option }}
                    </v-chip>
                  </v-chip-group>
                </div>
              </div>
              
              <!-- Commitment -->
              <div class="filter-section">
                <h3 class="filter-title">
                  <v-icon color="primary" size="small" class="mr-2">mdi-clock-time-four-outline</v-icon>
                  Commitment
                </h3>
                <div class="filter-chips">
                  <v-chip-group v-model="selectedCommitmentModel" key="commitment" multiple>
                    <v-chip
                      v-for="option in filterCommitment"
                      :key="option"
                      :value="option"
                      class="ma-1"
                      filter
                      color="primary"
                      variant="outlined"
                    >
                      {{ option }}
                    </v-chip>
                  </v-chip-group>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Jobs Section -->
        <v-col cols="12" sm="7" md="7" lg="8" xl="9">
          <!-- No results message -->
          <v-card v-if="filteredJobs.length === 0" class="text-center pa-6 empty-state" elevation="3">
            <v-icon size="64" color="grey" class="mb-4">mdi-file-search-outline</v-icon>
            <h3 class="text-h5 font-weight-medium mb-2">No careers match your filters</h3>
            <p class="text-body-1 mb-4 text-medium-emphasis">Try adjusting your filters to see more results</p>
            <v-btn color="primary" @click="clearFilters">Clear All Filters</v-btn>
          </v-card>

          <!-- Results grid -->
          <div v-else>
            <v-row class="job-cards-container">
              <v-col
                v-for="job in displayedJobs"
                :key="job.ID"
                cols="12"
                sm="12"
                md="6"
                lg="4"
                xl="4"
                class="job-card-col"
              >
                <v-card class="job-card" elevation="3" @click="viewJobDetails(job.ID)">
                  <!-- Save button -->
                  <v-btn
                    icon
                    variant="text"
                    density="comfortable"
                    class="save-btn"
                    @click.stop="toggleHeart(job.ID)"
                  >
                    <v-icon :color="isJobSaved(job.ID) ? 'red' : 'grey-lighten-1'" size="24">
                      {{ heartIcon(job.ID) }}
                    </v-icon>
                  </v-btn>

                  <!-- Card header -->
                  <div class="job-card-header">
                    <h3 class="job-title">{{ job.Occupation }}</h3>
                    <div class="job-salary">
                      <v-icon color="success" class="mr-1" size="small">mdi-currency-usd</v-icon>
                      {{ formatSalaryRange(job.Income_Min, job.Income_Max) }}
                    </div>
                  </div>

                  <!-- Card content -->
                  <v-divider class="my-2"></v-divider>
                  
                  <!-- Job attributes -->
                  <div class="job-attributes">
                    <div class="attribute">
                      <span class="attribute-label">Regulated:</span>
                      <v-chip
                        size="x-small"
                        :color="getColor(job.Regulated)"
                        class="ms-2"
                        variant="flat"
                      >
                        {{ job.Regulated }}
                      </v-chip>
                    </div>
                    
                    <div class="attribute">
                      <span class="attribute-label">Type:</span>
                      <span class="attribute-value">{{ job.Commitment }}</span>
                    </div>

                    <div class="attribute">
                      <span class="attribute-label">Prospects:</span>
                      <v-chip
                        size="x-small"
                        :color="getColor(job.Prospects.replace(' ', '_'))"
                        class="ms-2"
                        variant="flat"
                      >
                        {{ job.Prospects }}
                      </v-chip>
                    </div>

                    <div class="attribute">
                      <span class="attribute-label">Work Mode:</span>
                      <span class="attribute-value">{{ job.Virtual ? job.Virtual.split(',')[0] : 'N/A' }}</span>
                    </div>
                  </div>

                  <!-- View details button -->
                  <v-card-actions class="job-card-actions">
                    <v-btn
                      color="primary"
                      variant="tonal"
                      block
                      :to="{ name: 'JobDetails', params: { jobId: job.ID } }"
                      prepend-icon="mdi-information-outline"
                    >
                      View Details
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>

            <!-- Loading indicator for infinite scroll -->
            <div 
              v-if="hasMoreJobs" 
              ref="loadMoreTrigger" 
              class="text-center py-6"
            >
              <v-progress-circular
                v-if="loadingMore"
                indeterminate
                color="primary"
                size="32"
              ></v-progress-circular>
              <p v-else class="text-body-2 text-medium-emphasis">Loading more results...</p>
            </div>

            <div v-else-if="displayedJobs.length > initialLoadCount" class="text-center py-4">
              <p class="text-body-2 text-medium-emphasis">You've reached the end of the results</p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>

  <!-- Scroll to top button -->
  <v-btn
    v-show="showScrollTop"
    color="primary"
    icon
    fixed
    bottom
    right
    class="scroll-to-top-btn"
    @click="scrollToTop"
  >
    <v-icon>mdi-arrow-up</v-icon>
  </v-btn>
</template>

<script>
import { ref, onMounted, nextTick, computed, onUnmounted, watch } from 'vue';
import { useUserStore } from '../store/store';
import router from '../router/router';
import instance from '../store/axios';
import {
  filterRegulated,
  filterCompetition,
  filterJobGrowth,
  filterCommitment,
  filterVirtual,
} from '../config/forms';

export const iconMap = {
  Yes: { color: 'warning', icon: 'mdi-alert-circle' },
  Both: { color: 'info', icon: 'mdi-alert-box' },
  No: { color: 'success', icon: 'mdi-check-circle' },
  Very_Good: { color: 'success', icon: 'mdi-finance' },
  Good: { color: 'success', icon: 'mdi-finance' },
  Fair: { color: 'warning', icon: 'mdi-finance' },
  Stable: { color: 'info', icon: 'mdi-finance' },
  Low: { color: 'success', icon: 'mdi-finance' },
  High: { color: 'info', icon: 'mdi-finance' },
  Steady: { color: 'info', icon: 'mdi-finance' },
  Growing: { color: 'success', icon: 'mdi-finance' },
};

export default {
  setup() {
    // Data
    const jobs = ref([]);
    const jobFamilies = ref([]);
    const selectedJobFamilies = ref([]);
    const selectedRegulated = ref([]);
    const selectedCompetition = ref([]);
    const selectedJobGrowth = ref([]);
    const selectedVirtual = ref([]);
    const selectedCommitment = ref([]);
    const isLoading = ref(true);
    const loadingMore = ref(false);
    const userStore = useUserStore();
    const showScrollTop = ref(false);
    const loadMoreTrigger = ref(null);
    const intersectionObserver = ref(null);

    // New model bindings for v-chip-groups
    const selectedJobFamiliesModel = ref([]);
    const selectedRegulatedModel = ref([]);
    const selectedCompetitionModel = ref([]);
    const selectedJobGrowthModel = ref([]);
    const selectedVirtualModel = ref([]);
    const selectedCommitmentModel = ref([]);
    
    // Watch the model bindings and update the filter arrays
    watch(selectedJobFamiliesModel, (newVal) => {
      selectedJobFamilies.value = [...newVal];
    });
    
    watch(selectedRegulatedModel, (newVal) => {
      selectedRegulated.value = [...newVal];
    });
    
    watch(selectedCompetitionModel, (newVal) => {
      selectedCompetition.value = [...newVal];
    });
    
    watch(selectedJobGrowthModel, (newVal) => {
      selectedJobGrowth.value = [...newVal];
    });
    
    watch(selectedVirtualModel, (newVal) => {
      selectedVirtual.value = [...newVal];
    });
    
    watch(selectedCommitmentModel, (newVal) => {
      selectedCommitment.value = [...newVal];
    });
    
    // Pagination
    const initialLoadCount = 12;
    const loadIncrement = 8;
    const currentPage = ref(1);
    const cf_saved = ref(
      sessionStorage.getItem('cf_saved') ? JSON.parse(sessionStorage.getItem('cf_saved')) : []
    );

    const clearFilters = () => {
      selectedJobFamilies.value = [];
      selectedRegulated.value = [];
      selectedCompetition.value = [];
      selectedJobGrowth.value = [];
      selectedVirtual.value = [];
      selectedCommitment.value = [];
      
      // Clear the model bindings
      selectedJobFamiliesModel.value = [];
      selectedRegulatedModel.value = [];
      selectedCompetitionModel.value = [];
      selectedJobGrowthModel.value = [];
      selectedVirtualModel.value = [];
      selectedCommitmentModel.value = [];
      
      currentPage.value = Math.ceil(initialLoadCount / loadIncrement);
    };

    // Apply all filters to the jobs
    const filteredJobs = computed(() => {
      return jobs.value.filter((job) => {
        const matchesFamilies =
          selectedJobFamilies.value.length === 0 ||
          job.Count.some((family) => selectedJobFamilies.value.includes(family));

        const matchesRegulated =
          selectedRegulated.value.length === 0 || selectedRegulated.value.includes(job.Regulated);

        const matchesCompetition =
          selectedCompetition.value.length === 0 || selectedCompetition.value.includes(job.Prospects);

        const matchesJobGrowth =
          selectedJobGrowth.value.length === 0 || selectedJobGrowth.value.includes(job.Trend);

        const matchesVirtual =
          selectedVirtual.value.length === 0 ||
          selectedVirtual.value.some((selected) =>
            job.Virtual.split(',').map((v) => v.trim()).includes(selected)
          );

        const matchesCommitment =
          selectedCommitment.value.length === 0 ||
          selectedCommitment.value.some((selected) =>
            job.Commitment.split(',').map((c) => c.trim()).includes(selected)
          );

        return (
          matchesFamilies &&
          matchesRegulated &&
          matchesCompetition &&
          matchesJobGrowth &&
          matchesVirtual &&
          matchesCommitment
        );
      });
    });

    // Currently displayed jobs (for pagination)
    const displayedJobs = computed(() => {
      const end = Math.min(currentPage.value * loadIncrement, filteredJobs.value.length);
      return filteredJobs.value.slice(0, end);
    });

    // Whether there are more jobs to load
    const hasMoreJobs = computed(() => {
      return displayedJobs.value.length < filteredJobs.value.length;
    });

    // Initialize intersection observer for infinite scrolling
    const setupInfiniteScroll = () => {
      // Disconnect any existing observer
      if (intersectionObserver.value) {
        intersectionObserver.value.disconnect();
      }
      
      // Create new observer
      intersectionObserver.value = new IntersectionObserver(([entry]) => {
        // When the load more trigger becomes visible and we have more jobs to load
        if (entry.isIntersecting && hasMoreJobs.value && !loadingMore.value) {
          loadMore();
        }
      }, {
        rootMargin: '100px', // Start loading when within 100px of the trigger
        threshold: 0.1
      });
      
      // Start observing the trigger element when it exists
      if (loadMoreTrigger.value) {
        intersectionObserver.value.observe(loadMoreTrigger.value);
      }
    };

    // Reconnect observer when trigger element changes
    watch(() => loadMoreTrigger.value, (newVal) => {
      if (newVal && intersectionObserver.value) {
        intersectionObserver.value.observe(newVal);
      }
    });

    // Load more jobs for pagination
    const loadMore = () => {
      if (loadingMore.value || !hasMoreJobs.value) return;
      
      loadingMore.value = true;
      setTimeout(() => {
        currentPage.value += 1;
        loadingMore.value = false;
        
        // Let the DOM update, then check if we should load more
        nextTick(() => {
          if (loadMoreTrigger.value && hasMoreJobs.value) {
            // Check if the trigger is already in view after loading and we should load more
            const rect = loadMoreTrigger.value.getBoundingClientRect();
            const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
            
            if (isVisible && !loadingMore.value) {
              loadMore();
            }
          }
        });
      }, 600); // Slight delay for better UX
    };

    // Reset pagination when filters change
    watch(filteredJobs, () => {
      currentPage.value = Math.ceil(initialLoadCount / loadIncrement);
      
      // Re-setup infinite scroll after filter changes
      nextTick(setupInfiniteScroll);
    });

    // Toggle chip selection for filters
    const toggleFilter = (array, value) => {
      const index = array.indexOf(value);
      if (index === -1) {
        array.push(value);
      } else {
        array.splice(index, 1);
      }
    };

    // Check if a job is saved
    const isJobSaved = (jobId) => cf_saved.value.includes(jobId);

    // Get heart icon based on saved status
    const heartIcon = (jobId) => (isJobSaved(jobId) ? 'mdi-heart' : 'mdi-heart-outline');

    // Get color for various statuses
    const getColor = (category) => iconMap[category]?.color || 'grey';
    
    // Get icon for various statuses
    const getIcon = (category) => iconMap[category]?.icon || 'mdi-information';

    // Format salary range for display
    const formatSalaryRange = (min, max) => {
      return `$${Math.round(min/1000)}k - $${Math.round(max/1000)}k`;
    };

    // Navigate to job details
    const viewJobDetails = (jobId) => {
      router.push({ name: 'JobDetails', params: { jobId } });
    };

    // Toggle heart (save/unsave job)
    const toggleHeart = async (jobId) => {
      if (isJobSaved(jobId)) {
        const index = cf_saved.value.indexOf(jobId);
        cf_saved.value.splice(index, 1);
        await nextTick();
        try {
          await instance.delete('profile/saved', {
            data: { jobId },
            headers: { 'Content-Type': 'application/json' },
          });
        } catch (error) {
          console.error('Failed to delete job:', error);
          cf_saved.value.push(jobId);
        }
      } else {
        cf_saved.value.push(jobId);
        await nextTick();
        try {
          await instance.put('profile/saved', {
            data: { jobId },
            headers: { 'Content-Type': 'application/json' },
          });
        } catch (error) {
          console.error('Failed to save job:', error);
          const index = cf_saved.value.indexOf(jobId);
          cf_saved.value.splice(index, 1);
        }
      }
      sessionStorage.setItem('cf_saved', JSON.stringify(cf_saved.value));
    };

    // Go back
    const clearAndGoBack = () => {
      router.go(-1);
    };

    // Format family name for display
    const formatFamilyName = (family) => {
      return family.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
    };

    // Scroll to top
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // Show/hide scroll to top button
    const handleScroll = () => {
      showScrollTop.value = window.scrollY > 300;
    };

    // Load data on mount
    onMounted(async () => {
      window.addEventListener('scroll', handleScroll);
      
      try {
        isLoading.value = true;

        const storedResults = sessionStorage.getItem('cf_results');
        if (storedResults) {
          jobs.value = JSON.parse(storedResults);
        } else if (userStore.searchResults.length > 0) {
          jobs.value = userStore.searchResults;
        } else {
          const cf_search = sessionStorage.getItem('cf_search')
            ? JSON.parse(sessionStorage.getItem('cf_search'))
            : null;
          const response = await instance.get('search/results', {
            params: cf_search,
            headers: { 'Content-Type': 'application/json' },
          });

          jobs.value = response.data.map((job) => ({
            Occupation: job.occupation,
            Description: job.description,
            Prospects: job.job_prospects,
            Regulated: job.regulated,
            Income_Min: job.income_min,
            Income_Max: job.income_max,
            ID: job.id,
            Trend: job.job_growth,
            Virtual: job.virtual,
            Count: job.Families,
            Commitment: job.commitment,
          }));

          userStore.setCfResults(jobs.value);
        }

        // Populate job families
        if (Array.isArray(userStore.jobFamilies) && userStore.jobFamilies.length > 0) {
          jobFamilies.value = userStore.jobFamilies;
        } else {
          const familiesSet = new Set();
          jobs.value.forEach((job) => {
            job.Count.forEach((family) => {
              familiesSet.add(family);
            });
          });
          jobFamilies.value = Array.from(familiesSet);
          userStore.setJobFamilies(jobFamilies.value);
        }

        // Set initial page to show initialLoadCount items
        currentPage.value = Math.ceil(initialLoadCount / loadIncrement);
        
      } catch (error) {
        console.error('Failed to fetch jobs:', error);
      } finally {
        isLoading.value = false;
        
        // Setup infinite scroll after data is loaded
        nextTick(setupInfiniteScroll);
      }
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
      
      // Clean up observer
      if (intersectionObserver.value) {
        intersectionObserver.value.disconnect();
      }
    });

    return {
      jobs,
      filteredJobs,
      displayedJobs,
      hasMoreJobs,
      loadMore,
      loadingMore,
      jobFamilies,
      selectedJobFamilies,
      selectedRegulated,
      selectedCompetition,
      selectedJobGrowth,
      selectedVirtual,
      selectedCommitment,
      heartIcon,
      isLoading,
      isJobSaved,
      toggleHeart,
      clearAndGoBack,
      getColor,
      getIcon,
      formatFamilyName,
      formatSalaryRange,
      toggleFilter,
      viewJobDetails,
      filterRegulated,
      filterCompetition,
      filterJobGrowth,
      filterVirtual,
      filterCommitment,
      clearFilters,
      initialLoadCount,
      showScrollTop,
      scrollToTop,
      loadMoreTrigger,
      selectedJobFamiliesModel,
      selectedRegulatedModel,
      selectedCompetitionModel,
      selectedJobGrowthModel,
      selectedVirtualModel,
      selectedCommitmentModel,
    };
  },
};
</script>

<style scoped>
/* Page layout */
.results-page {
  min-height: 100vh;
  background-color: #f8f9fa;
  padding-bottom: 4rem;
}

.results-header {
  background-color: white;
  padding: 1rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
}

.back-btn {
  margin-right: 1rem;
}

.results-container {
  max-width: 1600px;
}

/* Filters card */
.filters-card {
  position: sticky;
  top: 5rem;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 2rem;
  width: 100%;
}

.results-count {
  font-size: 0.875rem;
  padding: 0.25rem 0.75rem;
}

.filters-content {
  max-height: calc(100vh - 250px);
  overflow-y: auto;
  padding: 1.5rem;
  overflow-x: hidden;
}

.filter-section {
  margin-bottom: 1.5rem;
}

.filter-title {
  font-size: 0.95rem;
  font-weight: 600;
  color: #344767;
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
}

.filter-chips {
  margin-left: 0.25rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow: visible; /* Prevent horizontal scrolling */
}

:deep(.v-chip-group) {
  flex-wrap: wrap;
  max-width: 100%;
}

/* Job cards */
.job-cards-container {
  row-gap: 1.5rem;
}

.job-card-col {
  display: flex;
}

.job-card {
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  position: relative;
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.job-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1) !important;
}

.save-btn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 1;
}

.job-card-header {
  padding: 1.5rem 1.5rem 0.5rem;
}

.job-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #344767;
  margin-bottom: 0.5rem;
  padding-right: 2rem;
  line-height: 1.4;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 3em;
}

.job-salary {
  color: #4caf50;
  font-weight: 600;
  font-size: 0.95rem;
  display: flex;
  align-items: center;
}

.job-attributes {
  padding: 0.75rem 1.5rem 1.25rem;
  flex-grow: 1;
}

.attribute {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}

.attribute-label {
  font-weight: 500;
  color: #64748b;
  font-size: 0.875rem;
}

.attribute-value {
  margin-left: 0.5rem;
  font-size: 0.875rem;
  color: #344767;
}

.job-card-actions {
  padding: 0.75rem 1rem 1rem;
}

/* Empty state */
.empty-state {
  border-radius: 12px;
}

/* Scroll to top button */
.scroll-to-top-btn {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 99;
  margin: 0;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

/* Responsive adjustments */
@media (max-width: 960px) {
  .filters-content {
    max-height: none;
  }

  .filters-card {
    position: relative;
    top: 0;
    margin-bottom: 2rem;
  }
  
  .job-title {
    font-size: 1rem;
  }
}
</style>