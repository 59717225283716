<template>
  <v-app>  <!-- Make sure to wrap with v-app -->
    <v-main class="home-splash" style="background-color: #edf8ff; padding: 0;">
      <!-- Removed top navigation with login button -->
    
    <!-- Hero Section -->
    <div class="hero-section">
      <v-container>
        <v-row align="center" justify="center" class="hero-content pt-12">
          <v-col cols="12" class="text-center hero-header mb-8">
            <h1 class="hero-title">Canadian IEHP Landing Zone</h1>
          </v-col>
        </v-row>
        
        <v-row align="center" justify="center">
          <v-col cols="12" md="6" lg="5" class="hero-text pe-md-8 order-2 order-md-1">
            <p class="hero-subtitle mb-6">
              Search a vetted database of over 600 Canadian Healthcare Careers. Understand the regulatory bodies and how to apply to these various careers. Enroll in a curated coaching program to ensure you succeed and kickstart your career in Canada!
            </p>
            <div class="stats-badge mb-6">
              <span>500+ Careers</span>
              <v-divider vertical class="mx-3"></v-divider>
              <span>50+ Coaching programs</span>
            </div>
            <div class="text-center text-md-left buttons-container">
              <v-btn 
                color="#4b9d77" 
                size="large" 
                rounded 
                elevation="2" 
                class="get-started-btn"
                @click="goToFormWizard"
              >
                <v-icon start>mdi-rocket-launch</v-icon>
                Try CareerFit.ca for free!
              </v-btn>
              
              <div class="text-center text-md-left login-text">
                <span>Already have an account?</span>
                <v-btn 
                  variant="text" 
                  color="primary"
                  class="px-2 login-link"
                  @click="goToLogin"
                >
                  <v-icon size="small" class="mr-1">mdi-login</v-icon>
                  Log in here
                </v-btn>
              </div>
            </div>
          </v-col>
          
          <v-col cols="12" md="6" lg="5" class="hero-image-col d-flex justify-center order-1 order-md-2 mb-6 mb-md-0">
            <div class="hero-image-container">
              <v-img 
                :src="require('../assets/pictures/CF-2.png')" 
                alt="Career image" 
                class="hero-image"
                cover
              ></v-img>
            </div>
          </v-col>
        </v-row>
      </v-container>
      
      <!-- Wave Separator -->
      <div class="wave-separator">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 120" preserveAspectRatio="none">
          <path fill="#ffffff" d="M0,32L80,42.7C160,53,320,75,480,80C640,85,800,75,960,64C1120,53,1280,43,1360,37.3L1440,32L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
        </svg>
      </div>
    </div>

<!-- Features Section -->
<div class="features-section" ref="featuresSection">
  <v-container>
    <!-- Navigation Buttons Above Features -->
    <v-row justify="center" class="mb-8">
      <v-col cols="12" sm="10" md="8" lg="6" class="d-flex justify-center flex-wrap">
        <v-btn 
          rounded
          color="primary" 
          variant="outlined"
          size="large" 
          class="additional-nav-btn mx-2 my-2"
          @click="goToAboutUs"
        >
          <v-icon start>mdi-account-group</v-icon>
          About Us
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="mb-10">
      <v-col cols="12" class="text-center">
        <h2 class="features-title">How CareerFit.ca Helps You</h2>
        <div class="features-divider"></div>
      </v-col>
    </v-row>
    
    <v-row justify="center">
      <v-col cols="12" sm="10" md="4" class="feature-col">
        <v-card class="feature-card" elevation="3" rounded="lg">
          <div class="feature-icon-wrapper primary-bg">
            <v-icon size="x-large" color="white">mdi-list-status</v-icon>
          </div>
          <v-card-title class="feature-title">Learn What Fits You</v-card-title>
          <v-card-text class="feature-text">
            Filter through 16 Job Families to learn what options are available within the healthcare space in Canada, tailored to you!
          </v-card-text>
        </v-card>
      </v-col>
      
      <v-col cols="12" sm="10" md="4" class="feature-col">
        <v-card class="feature-card" elevation="3" rounded="lg">
          <div class="feature-icon-wrapper custom-green-bg">
            <v-icon size="x-large" color="white">mdi-check-circle</v-icon>
          </div>
          <v-card-title class="feature-title">Learn Canadian Regulatory Bodies</v-card-title>
          <v-card-text class="feature-text">
            Regulated careers made easy! CareerFit.ca guides you through everything you need to understand what regulatory bodies expect from you!
          </v-card-text>
        </v-card>
      </v-col>
      
      <v-col cols="12" sm="10" md="4" class="feature-col">
        <v-card class="feature-card" elevation="3" rounded="lg">
          <div class="feature-icon-wrapper primary-bg">
            <v-icon size="x-large" color="white">mdi-briefcase-check</v-icon>
          </div>
          <v-card-title class="feature-title">Prep and Land your Career</v-card-title>
          <v-card-text class="feature-text">
            CareerFit.ca brings access to over a dozen individual and group based coaching sessions to ensure you secure the career you want!
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</div>
</v-main>
</v-app>
</template>

<script>
export default {
  name: 'HomeSplash',
  data() {
    return {
      activeTab: null,
    };
  },
  methods: {
    goToLogin() {
      this.$router.push({ name: 'UserLogin' });
    },
    goToFormWizard() {
      this.$router.push({ name: 'UserFormWizard' });
    },
    goToHowItWorks() {
      this.$router.push({ name: 'HowItWorks' });
    },
    goToAboutUs() {
      this.$router.push({ name: 'AboutUs' });
    },
    resetActiveTab() {
      this.$nextTick(() => {
        this.activeTab = null;
      });
    }
    // Remove scrollTo methods as they're no longer needed
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler(to) {
        if (to === '/') {
          this.resetActiveTab();
        }
      },
    },
  },
};
</script>

<style scoped>
/* Main Styles */
.home-splash {
  min-height: 100vh;
  overflow-x: hidden;
}

/* Hero Section */
.hero-section {
  position: relative;
  padding: 5vh 0 15vh;
  overflow: hidden;
  background: linear-gradient(to bottom right, #edf8ff, #e1f0ff);
}

.hero-content {
  position: relative;
  z-index: 2;
  padding-top: 5vh;
}

.hero-header {
  margin-top: 3rem;
}

.hero-title {
  font-size: clamp(2.5rem, 6vw, 4rem);
  font-weight: 800;
  color: #1a237e;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  position: relative;
  display: inline-block;
}

.hero-title::after {
  content: '';
  position: absolute;
  width: 60%;
  height: 6px;
  background: linear-gradient(to right, #1a237e, #42a5f5);
  bottom: -0.75rem;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 3px;
}

.hero-subtitle {
  font-size: clamp(1rem, 1.2vw, 1.2rem);
  line-height: 1.6;
  color: #455a64;
  margin: 0 auto;
}

.stats-badge {
  display: inline-flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 2rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  font-weight: 600;
  color: #1a237e;
  margin: 0 auto;
}

.buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.custom-green-bg {
  background-color: #4b9d77 !important; /* R75G157B119 */
}

@media (min-width: 768px) {
  .buttons-container {
    align-items: flex-start;
  }
}

.login-link {
  text-decoration: none;
  font-weight: 500;
  margin-left: 0.25rem;
  padding-left: 4px !important;
  padding-right: 4px !important;
  text-transform: none;
  letter-spacing: 0;
  min-height: 32px !important;
}

.login-link:hover {
  background-color: rgba(26, 35, 126, 0.05);
  text-decoration: underline;
}

/* Updated get-started-btn styling */
.get-started-btn {
  padding: 0.75rem 2rem;
  font-weight: 600;
  text-transform: none;
  font-size: 1.1rem;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
  min-width: 280px;
}

.get-started-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* New login text styling */
.login-text {
  margin-top: 1rem;
  font-size: 0.95rem;
  color: #455a64;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.login-btn {
  background-color: transparent;
  border: 2px solid rgba(26, 35, 126, 0.7);
  color: #1a237e;
}

.hero-image-container {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.hero-image {
  border-radius: 16px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
  transform: perspective(1000px) rotateY(-8deg);
  transition: all 0.5s ease;
}

.hero-image:hover {
  transform: perspective(1000px) rotateY(0);
}

/* Wave separator */
.wave-separator {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  line-height: 0;
  transform: translateY(1px);
}

.wave-separator svg {
  width: 100%;
  height: 80px;
}

/* Additional Navigation Buttons */
.additional-nav-btn {
  padding: 0.75rem 2rem;
  font-weight: 500;
  text-transform: none;
  font-size: 1rem;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
}

.additional-nav-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

@media (max-width: 959px) {
  .hero-content {
    padding-top: 3vh;
  }
  
  .hero-header {
    margin-top: 1.5rem;
  }
  
  .hero-section {
    padding: 3vh 0 12vh;
  }
  
  .hero-text {
    text-align: center;
    padding: 0 1rem;
  }
  
  .hero-subtitle, .stats-badge {
    margin: 0 auto 1.5rem;
  }
  
  .hero-image-container {
    max-width: 80%;
    margin: 0 auto 2rem;
  }
  
  .wave-separator svg {
    height: 50px;
  }
  
  .additional-nav-btn {
    width: 100%;
    margin: 0.5rem 0;
  }
  
  .get-started-btn, .login-btn {
    width: 100%;
    max-width: 300px;
  }
}

/* Features Section */
.features-section {
  padding: 5vh 0 8vh;
  background-color: #ffffff;
  position: relative;
  z-index: 3;
}

.features-title {
  font-size: clamp(1.8rem, 3vw, 2.5rem);
  font-weight: 700;
  color: #1a237e;
  margin-bottom: 1rem;
}

.features-divider {
  width: 80px;
  height: 4px;
  background: linear-gradient(to right, #1a237e, #42a5f5);
  margin: 0 auto;
  border-radius: 2px;
}

.feature-col {
  display: flex;
}

.feature-card {
  border-radius: 16px !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.12) !important;
}

.feature-icon-wrapper {
  width: 100%;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.primary-bg {
  background-color: #1a237e;
}

.success-bg {
  background-color: #2e7d32;
}

.feature-title {
  font-size: 1.3rem !important;
  font-weight: 700 !important;
  color: #333333;
  text-align: center;
  line-height: 1.4;
}

.feature-text {
  color: #455a64;
  text-align: center;
  line-height: 1.6;
  padding: 0 1rem 1.5rem;
}

@media (max-width: 959px) {
  .feature-col {
    margin-bottom: 1.5rem;
  }
  
  .features-section {
    padding-bottom: 3vh;
  }
}
</style>