<template>
  <div class="fill-height">
    <!-- Loading spinner -->
    <v-container v-if="isLoading" class="d-flex flex-row align-center justify-center" style="height: 100vh;">
      <v-row justify="center" align="center">
        <v-col class="text-center">
          <v-progress-circular
            indeterminate
            size="70"
            width="7"
            color="primary">
          </v-progress-circular>
        </v-col>
      </v-row>
    </v-container>

    <!-- Course content -->
    <div v-else class="coaching-details-page">
      <!-- Header section with back button and title -->
      <div class="coaching-header">
        <v-container fluid>
          <v-row align="center">
            <v-col cols="auto">
              <v-btn icon color="primary" @click="$router.go(-1)" variant="text" class="back-btn">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </v-col>
            <v-col>
              <h1 class="text-h5 font-weight-bold text-primary mb-0">{{ courseData.name }}</h1>
            </v-col>
          </v-row>
        </v-container>
      </div>
      
      <v-container fluid class="pt-0 content-container">
        <v-row justify="center">
          <v-col cols="12" sm="12" md="9" lg="7" xl="7">
            <!-- Video Section -->
            <v-card class="mb-6" elevation="2">
              <v-card-text class="pa-0">
                <!-- Video Element -->
                <div class="video-container">
                  <video
                    id="videoPlayer"
                    controls
                    preload="none"
                    @play="loadVideo"
                    :poster="courseData.path ? `https://mvp.careerfit.ca/coaching/splashpics/${courseData.path}.png` : ''"
                  ></video>
                </div>
              </v-card-text>
              
              <!-- Video details section -->
              <v-card-text class="pt-4">
                <div class="d-flex align-center flex-wrap">
                  <v-icon color="primary" size="small" class="mr-1">mdi-clock-time-four-outline</v-icon>
                  <span class="text-body-2">{{ courseData.duration }} minutes</span>
                  <v-divider vertical class="mx-3"></v-divider>
                  <v-icon color="primary" size="small" class="mr-1">mdi-television-play</v-icon>
                  <span class="text-body-2 mr-3">Video Course</span>
                  <v-divider vertical class="mx-2"></v-divider>
                  <v-chip
                    :color="enrollmentStatus === 'Enrolled' ? 'green' : enrollmentStatus === 'Pending Enroll' ? 'orange' : 'grey'"
                    size="x-small"
                    class="ml-2"
                    pill
                  >
                    {{ enrollmentStatus }}
                  </v-chip>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <!-- Course Info Section -->
        <v-row justify="center">
          <v-col cols="12" sm="12" md="9" lg="7" xl="7">
            <v-row>
              <v-col cols="12" md="7">
                <v-card class="mb-6" elevation="2">
                  <v-card-title class="text-h6">
                    <v-icon color="primary" class="mr-2">mdi-information-outline</v-icon>
                    Course Description
                  </v-card-title>
                  <v-card-text>
                    <p class="text-body-1">{{ courseData.description }}</p>
                    
                    <v-divider class="my-4"></v-divider>
                    
                    <v-card-title class="text-h6 px-0">
                      <v-icon color="primary" class="mr-2">mdi-trophy-outline</v-icon>
                      What You'll Learn
                    </v-card-title>
                    <p class="text-body-1">{{ courseData.outcomes }}</p>
                  </v-card-text>
                </v-card>
              </v-col>
              
              <v-col cols="12" md="5">
                <v-card class="mb-4" elevation="2">
                  <v-card-title class="text-h6">
                    <v-icon color="primary" class="mr-2">mdi-account-school</v-icon>
                    Course Instructor
                  </v-card-title>
                  <v-card-text>
                    <div class="d-flex align-center mb-4">
                      <v-avatar size="64" class="mr-4">
                        <v-img
                          :src="getInstructorImageUrl(courseData.pickyourcoach)"
                          :alt="courseData.pickyourcoach"
                        >
                          <template v-slot:placeholder>
                            <v-icon size="40" color="grey darken-2">mdi-account</v-icon>
                          </template>
                        </v-img>
                      </v-avatar>
                      <div>
                        <h3 class="text-h6 mb-1">{{ courseData.pickyourcoach }}</h3>
                        <p class="text-caption text-medium-emphasis">Course Instructor</p>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
                
                <!-- Enrollment Card -->
                <v-card elevation="2">
                  <v-card-title class="text-h6">
                    <v-icon color="primary" class="mr-2">mdi-calendar-check</v-icon>
                    Enrollment
                  </v-card-title>
                  <v-card-text>
                    <v-btn 
                      color="primary" 
                      block 
                      class="mt-2" 
                      :disabled="enrollmentStatus !== 'Not Enrolled'"
                      @click="dialog = true"
                    >
                      Enroll in Course
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      
      <!-- Enrollment Dialog -->
      <v-dialog v-model="dialog" max-width="800" transition="dialog-bottom-transition">
        <v-card class="enrollment-dialog">
          <v-toolbar color="primary" dark>
            <v-toolbar-title class="text-h6">
              <v-icon class="mr-2">mdi-calendar-plus</v-icon>
              Schedule Your Session
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          
          <v-card-text class="pt-6">
            <h3 class="text-h6 mb-4 text-center">{{ courseData.name }}</h3>
            <p class="text-body-2 text-center mb-6">Please select a date and time that works for you.</p>
            
            <v-row>
              <v-col cols="12">
                <v-card flat class="pa-3 mb-4 rounded-lg" color="grey lighten-4" elevation="0">
                  <p class="text-body-2 font-weight-medium mb-1">Session Details</p>
                  <div class="d-flex align-center mb-2">
                    <v-icon size="small" color="primary" class="mr-2">mdi-account</v-icon>
                    <span class="text-body-2">Instructor: {{ courseData.pickyourcoach }}</span>
                  </div>
                  <div class="d-flex align-center">
                    <v-icon size="small" color="primary" class="mr-2">mdi-clock-outline</v-icon>
                    <span class="text-body-2">Duration: {{ courseData.duration }} minutes</span>
                  </div>
                </v-card>
              </v-col>
            </v-row>
            
            <v-row>
              <v-col cols="12" md="7" order="2" order-md="1">
                <label class="text-subtitle-2 mb-2 d-block">Select Date</label>
                <div class="date-picker-container">
                  <v-date-picker
                    v-model="selectedDate"
                    color="primary"
                    width="100%"
                    elevation="0"
                    class="enrollment-date-picker mb-4"
                  ></v-date-picker>
                </div>
              </v-col>
              
              <v-col cols="12" md="5" order="1" order-md="2">
                <label class="text-subtitle-2 mb-2 d-block">Select Time</label>
                <v-card flat class="time-selector pa-3" color="grey lighten-4">
                  <v-chip-group
                    v-model="selectedTimeIndex"
                    column
                    mandatory
                    class="time-chip-group"
                  >
                    <v-chip
                      v-for="(time, index) in times"
                      :key="index"
                      filter
                      outlined
                      :value="index"
                      @click="selectedTime = time"
                      class="time-chip ma-1"
                    >
                      {{ time }}
                    </v-chip>
                  </v-chip-group>
                </v-card>
                
                <v-alert
                  v-if="selectedDate && selectedTime"
                  color="primary"
                  variant="tonal"
                  class="mt-4 mb-0"
                  icon="mdi-information-outline"
                  border="start"
                  density="compact"
                >
                  Your session will be scheduled for {{ formattedDate }} at {{ selectedTime }}
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
          
          <v-divider></v-divider>
          
          <v-card-actions class="pa-4">
            <v-btn
              variant="outlined"
              @click="dialog = false"
              class="text-none"
            >
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="confirmEnrollment"
              :disabled="!selectedDate || !selectedTime"
              :loading="confirmloading"
              class="text-none"
            >
              Schedule Session
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Success Dialog -->
      <v-dialog v-model="successDialog" max-width="400" persistent>
        <v-card class="success-dialog">
          <v-card-text class="pa-6 text-center">
            <v-avatar color="green" size="64" class="mb-4">
              <v-icon color="white" size="36">mdi-check</v-icon>
            </v-avatar>
            <h3 class="text-h6 mb-2">Session Scheduled!</h3>
            <p class="mb-0">Your session has been successfully scheduled for {{ formattedDate }} at {{ selectedTime }}.</p>
          </v-card-text>
          <v-card-actions class="pa-4 pt-0">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="successDialog = false" block>
              Done
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useUserStore } from '../store/store';
import instance from '../store/axios';

export default {
  props: ['sessionId'],
  setup(props) {
    const route = useRoute();
    const isLoading = ref(true);
    const confirmloading = ref(false);
    const videoLoaded = ref(false);
    const dialog = ref(false);
    const courseData = ref({});
    const sessionIdentifier = ref(props.sessionId || route.params.sessionId);
    const userStore = useUserStore();
    const menu = ref(false);
    const isMenuOpen = ref(false);
    const selectedDate = ref();
    const confirmDialog = ref(false);
    const selectedTime = ref('');
    const formattedDate = computed(() =>
      selectedDate.value ? selectedDate.value.toISOString().slice(0, 10) : ''
    );
    const selectedTimeIndex = ref(null);
    const successDialog = ref(false);
    const times = ref([
      '08:00', '09:00', '10:00', '11:00',
      '12:00', '13:00', '14:00', '15:00',
      '16:00', '17:00', '18:00',
    ]);

    // Format instructor name for image URL (convert to lowercase and replace spaces with underscores)
    const getInstructorImageUrl = (instructorName) => {
      if (!instructorName) return '';
      const formattedName = instructorName.toLowerCase().replace(/\s+/g, '_');
      return `https://mvp.careerfit.ca/coaching/instructors/${formattedName}.jpg`;
    };

    const confirmEnrollment = async () => {
      confirmloading.value = true;
      try {
        console.log('Sending enrollment request');
        console.log('Enrollment data:', {
          date: formattedDate.value,
          time: selectedTime.value,
          sessionId: sessionIdentifier.value,
          sessionName: courseData.value.name,
        });
        
        // Use direct fetch to ensure correct URL
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': instance.defaults.headers.common['Authorization']
        };
        
        await fetch(`${window.location.origin}/mvp/coaching/enroll`, {
          method: 'PUT',
          headers,
          body: JSON.stringify({
            date: formattedDate.value,
            time: selectedTime.value,
            sessionId: sessionIdentifier.value,
            sessionName: courseData.value.name,
          })
        });
        
        if (userStore.cf_coaching[sessionIdentifier.value]) {
          userStore.cf_coaching[sessionIdentifier.value][2] = 'Pending Enroll';
        } else {
          userStore.cf_coaching[sessionIdentifier.value] = [
            selectedTime.value,
            formattedDate.value,
            'Pending Enroll',
            courseData.value.name,
          ];
        }
        dialog.value = false;
        successDialog.value = true; // Show success dialog instead of confirmation dialog
        isMenuOpen.value = false;
      } catch (error) {
        console.error('Failed to enroll:', error);
      } finally {
        confirmloading.value = false;
      }
    };

    const fetchEnrolled = async () => {
      if (userStore.cf_coaching && Object.keys(userStore.cf_coaching).length > 0) {
        return;
      }
      try {
        console.log('Fetching enrolled data');
        
        // Make a direct fetch request to the correct URL
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': instance.defaults.headers.common['Authorization']
        };
        
        const response = await fetch(`${window.location.origin}/mvp/coaching/enroll`, {
          method: 'GET',
          headers
        });
        
        const data = await response.json();
        userStore.setEnrolledCoach(data.cf_coaching);
        console.log('EnrolledAPI response:', data.cf_coaching);
      } catch (error) {
        console.error('Error fetching enrolled data:', error);
      }
    };

    const fetchData = async () => {
      console.log('Fetching data for session ID:', sessionIdentifier.value);
      
      try {
        // Make a direct fetch request to the correct URL
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': instance.defaults.headers.common['Authorization']
        };
        
        console.log('Request URL:', `${window.location.origin}/mvp/coaching/${sessionIdentifier.value}`);
        console.log('Request headers:', headers);
        
        const response = await fetch(`${window.location.origin}/mvp/coaching/${sessionIdentifier.value}`, {
          method: 'GET',
          headers
        });
        
        console.log('Response status:', response.status);
        console.log('Response headers:', Object.fromEntries([...response.headers.entries()]));
        
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}: ${response.statusText}`);
        }
        
        const data = await response.json();
        console.log('API raw response data:', data);
        
        if (data && data.length > 0) {
          const session = data[0];
          console.log('Session data properties:', Object.keys(session));
          
          // Update with the new API structure
          courseData.value = {
            name: session.name,
            description: session.description,
            outcomes: session.outcomes,
            structure: session.structure,
            duration: session.time,
            path: session.path,
            id: session.id,
            pickyourcoach: session.pickyourcoach || 'Instructor Name'
          };
          console.log('Course data set successfully:', courseData.value);
        } else {
          console.error('No data returned from API or empty array. Response data:', data);
        }
      } catch (error) {
        console.error('Failed to fetch session:', error);
        console.error('Error details:', error.message);
        if (error.response) {
          console.error('Error response:', error.response);
        }
      }
    };

    const enrollmentStatus = computed(() =>
      userStore.cf_coaching && userStore.cf_coaching[sessionIdentifier.value]
        ? userStore.cf_coaching[sessionIdentifier.value][2]
        : 'Not Enrolled'
    );

    const getCognitoToken = () => {
      const tokenKeyPrefix = 'CognitoIdentityServiceProvider.fvej8gc1r8hu68e5qnb91e8k1';
      const keys = Object.keys(localStorage).filter((key) =>
        key.startsWith(`${tokenKeyPrefix}`)
      );
      const accessTokenKey = keys.find((key) => key.endsWith('.idToken'));
      return localStorage.getItem(accessTokenKey) || null;
    };

    const loadVideo = () => {
      if (videoLoaded.value) {
        return;
      }

      const token = getCognitoToken();
      if (!token) {
        console.error("No authentication token found");
        return;
      }

      const videoPath = courseData.value.path;
      if (!videoPath) {
        console.error("Video path not found");
        return;
      }

      const videoElement = document.getElementById("videoPlayer");
      if (!videoElement) {
        console.error("Video element not found");
        return;
      }

      const source = document.createElement("source");
      // Updated path structure for video
      source.src = `https://mvp.careerfit.ca/coaching/modules/${videoPath}.mp4?access_token=${token}`;
      source.type = "video/mp4";
      videoElement.innerHTML = ""; 
      videoElement.appendChild(source);

      console.log("Video source set to:", source.src.split('?')[0]); // Log without token

      videoElement.load();
      videoElement.addEventListener("canplay", () => {
        videoElement.play().catch((error) => {
          console.error("Error trying to play the video:", error);
        });
        isLoading.value = false;
        videoLoaded.value = true;
      });

      videoElement.addEventListener("error", (err) => {
        console.error("Error loading video:", err);
        isLoading.value = false;
        videoLoaded.value = false;
      });
    };

    onMounted(async () => {
      isLoading.value = true;
      videoLoaded.value = false; // Reset flag on mount
      try {
        await Promise.all([fetchData(), fetchEnrolled()]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      isLoading.value = false;
    });

    watch(
      () => route.params.sessionId,
      async () => {
        isLoading.value = true;
        sessionIdentifier.value = route.params.sessionId;
        videoLoaded.value = false; // Reset flag on navigation
        try {
          await Promise.all([fetchData(), fetchEnrolled()]);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
        isLoading.value = false;
      }
    );

    return {
      isLoading,
      isMenuOpen,
      menu,
      times,
      selectedDate,
      formattedDate,
      courseData,
      sessionIdentifier,
      enrollmentStatus,
      dialog,
      confirmDialog,
      confirmloading,
      selectedTime,
      confirmEnrollment,
      loadVideo,
      getInstructorImageUrl,
      selectedTimeIndex,
      successDialog,
    };
  },
};
</script>

<style scoped>
/* Page layout */
.coaching-details-page {
  min-height: 100vh;
  background-color: #f8f9fa;
  padding-bottom: 4rem;
}

.coaching-header {
  background-color: white;
  padding: 1rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
}

.back-btn {
  margin-right: 1rem;
}

.content-container {
  max-width: 1600px;
  padding-top: 1rem;
}

.video-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  overflow: hidden;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: #000;
}

/* Responsive dialog styles */
.enrollment-dialog {
  border-radius: 8px;
  max-height: 90vh;
  overflow-y: auto;
}

.date-picker-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.enrollment-date-picker {
  border-radius: 8px;
  background-color: #f5f5f5;
  overflow: hidden;
  width: 100%;
}

.time-selector {
  border-radius: 8px;
  height: auto;
  min-height: 12em;
  overflow-y: auto;
}

.time-chip-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.time-chip {
  min-width: 5em;
  flex: 0 0 auto;
  justify-content: center;
  margin: 0.375em !important;
}

.success-dialog {
  border-radius: 8px;
}

/* Selected chip styling */
.v-chip.v-chip--selected {
  background-color: var(--v-primary-base) !important;
  color: white !important;
}

.rounded-lg {
  border-radius: 8px;
}

/* Rest of your utility classes */
.mb-1 {
  margin-bottom: 4px;
}
.mb-2 {
  margin-bottom: 8px;
}
.mb-4 {
  margin-bottom: 16px;
}
.mb-6 {
  margin-bottom: 24px;
}
/* ... other utility classes ... */
</style>