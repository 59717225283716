<template>
    <div class="row">
        <div class="col">
            <h2>Logged Out successfully</h2>
            <router-link to="/">Logout</router-link>
        </div>
    </div>
    </template>

<script>
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import config from '../config/config';
const poolData = {
    UserPoolId: config.UserPoolId,
    ClientId: config.ClientId,
};
const userPool = new CognitoUserPool(poolData);

export function logout() {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser != null) {
    cognitoUser.signOut();
    }
}

export default {
    methods: {
    logout,
    },
};
</script>